import React from "react";

function Documents() {
  return (
    <div className="container-scroller">
      {/* <!-- partial --> */}
      <div className="container-fluid page-body-wrapper p-0">
        {/* <!-- partial --> */}
        <div className="main-panel">
          <div className="content-wrapper">
            {/* <!-- breadcrumb-start --> */}
            <div className="page-breadcrumb d-none d-md-flex align-items-center mb-5">
              <div className="breadcrumb-title pe-3">Estimate</div>
              <div className="ps-3">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb mb-0 p-0">
                    <li className="breadcrumb-item">
                      <a href="#">
                        <i className="fa-solid fa-house"></i>
                      </a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Documents
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
            {/* <!-- breadcrumb-end --> */}
            <div className="col-12">
              <div className="card main-card-table">
                <div className="card-header">
                  <div className="row align-items-center">
                    <div className="col-12 col-md-4">
                      <div className="card-label">
                        <img
                          src="/assets/images/document.svg"
                          alt=""
                          className="me-2"
                        />{" "}
                        Documents
                      </div>
                    </div>
                    <div className="col-12 col-md-8">
                      <div className="d-block d-md-flex justify-content-end">
                        <a href="#" type="button" className="btn ms-md-3">
                          <i className="fa-solid fa-repeat me-2"></i>Reprocess
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body table-responsive">
                  <table className="table table-modern">
                    <thead>
                      <tr>
                        <td width="20px"></td>
                        <th>Document</th>
                        <th>Version</th>
                        <th>Status</th>
                        <th>Processed Rule</th>
                        <th>Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div className="form-check form-check-sm form-check-custom">
                            <input
                              className="form-check-input"
                              type="checkbox"
                            />
                          </div>
                        </td>
                        <td>
                          <a
                            href="#"
                            className="text-primary text-decoration-underline"
                          >
                            Document 1
                          </a>
                        </td>
                        <td>1</td>
                        <td>Auto</td>
                        <td>250</td>
                        <td>2022-04-18 11:25</td>
                      </tr>
                      <tr>
                        <td>
                          <div className="form-check form-check-sm form-check-custom">
                            <input
                              className="form-check-input"
                              type="checkbox"
                            />
                          </div>
                        </td>
                        <td>
                          <a
                            href="#"
                            className="text-primary text-decoration-underline"
                          >
                            Document 2
                          </a>
                        </td>
                        <td>1</td>
                        <td>Rejected</td>
                        <td>250</td>
                        <td>2022-04-18 11:25</td>
                      </tr>
                      <tr>
                        <td>
                          <div className="form-check form-check-sm form-check-custom">
                            <input
                              className="form-check-input"
                              type="checkbox"
                            />
                          </div>
                        </td>
                        <td>
                          <a
                            href="#"
                            className="text-primary text-decoration-underline"
                          >
                            Document 3
                          </a>
                        </td>
                        <td>3</td>
                        <td>Auto</td>
                        <td>250</td>
                        <td>2022-04-18 11:25</td>
                      </tr>
                      <tr>
                        <td>
                          <div className="form-check form-check-sm form-check-custom">
                            <input
                              className="form-check-input"
                              type="checkbox"
                            />
                          </div>
                        </td>
                        <td>
                          <a
                            href="#"
                            className="text-primary text-decoration-underline"
                          >
                            Document 2
                          </a>
                        </td>
                        <td>5</td>
                        <td>Manual</td>
                        <td>250</td>
                        <td>2022-04-18 11:25</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- content-wrapper ends --> */}
          {/* <!-- partial:partials/_footer.html --> */}
          <footer className="footer">
            <div className="d-sm-flex justify-content-center">
              <p className="mb-0">
                Copyright © 2023{" "}
                <a
                  rel="noreferrer"
                  style={{ textDecorationLine: "underline !important" }}
                  href="https://scandiautomation.com/"
                  target="_blank"
                >
                  Scandi Automation
                </a>
                .
              </p>
            </div>
          </footer>
          {/* <!-- partial --> */}
        </div>
        {/* <!-- main-panel ends --> */}
      </div>
    </div>
  );
}

export default Documents;
