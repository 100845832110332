import { Field, Form, Formik } from "formik";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import PrimaryButton from "../../../components/Form/Buttons/PrimaryButton";
import PasswordInput from "../../../components/Form/Inputs/PasswordInput";
import TextInput from "../../../components/Form/Inputs/TextInput";
import authApi from "../../../services/apis/authApi";
import { getPathPrefix } from "../../../utils/coreUtils";
import ToastUtils from "../../../utils/ToastUtils";
import StorageManager from "../../library/utils/storageManager";
import LeftPanel from "../components/leftPanel.component";
import { OTP_SCREEN_TYPE } from "./otp.page";

const Login = () => {
  const navigate = useNavigate();
  const [t] = useTranslation();

  const handleLogin = async (values, actions) => {
    try {
      const companyDetails = JSON.parse(
        localStorage.getItem("domainInfo") ?? "{}"
      );

      const res = await authApi.authenticate({
        password: values.password,
        username: values.email.trim(),
        companyId: companyDetails?.companyId,
      });

      StorageManager.set("otpSentTime", moment().toISOString());

      if (res.data.isLoginFirstTime) {
        navigate(getPathPrefix("new-user"), {
          state: { encUrl: "encyptedKey=" + res.data.encryptedKey },
        });
      } else if (res.data.status != 200) {
        ToastUtils.serverError(t(`statusCodes.${res?.data?.code}`));
      } else {
        toast.success(t(`statusCodes.${res?.data?.code}`));
        navigate(getPathPrefix() + `otp`, {
          state: {
            type: OTP_SCREEN_TYPE.LOGIN,
            password: values.password,
            username: values.email,
          },
        });
      }
    } catch (error) {}

    actions.setSubmitting(false);
  };

  const initialFormValues = {
    email: "",
    password: "",
  };

  const formValidationSchema = Yup.object({
    email: Yup.string().required(t("companyLogin.usernameOrEmailRequired")),
    password: Yup.string()
      .label("Password")
      .required(t("companyLogin.passwordRequired")),
  });

  return (
    <>
      {
        <section className="page main-signin-wrapper container-layout align-items-center">
          <div className="row signpages bg-app-primary">
            <div className="col-md-12">
              <div className="row">
                <LeftPanel />
                <div className="col-lg-6 right-panel">
                  <div className="form-container d-flex flex-column justify-content-center">
                    <img
                        src={"/assets/images/scandi-name-automation.png"}
                        className="header-brand-img text-start mx-auto w-75"
                        alt="logo"
                      />
                    <div className="d-flex flex-column h-100 justify-content-center">
                      <Formik
                        initialValues={initialFormValues}
                        validateOnMount
                        className="mt-2"
                        validationSchema={formValidationSchema}
                        onSubmit={handleLogin}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isValid,
                          isSubmitting,
                          dirty,
                          ...props
                        }) => (
                          <Form onSubmit={handleSubmit}>
                            <div className="py-4">
                              <h3
                                className="text-primary-app mb-4 text-center"
                                style={{ fontWeight: "600" }}
                              >
                                {/* {langTranslation?.loginTitle} */}
                                {t("companyLogin.loginTitle")}
                              </h3>

                              <div className="col-12 mb-2">
                                <Field
                                  rightIcon={
                                    <i className="fa-solid fa-envelope"></i>
                                  }
                                  component={TextInput}
                                  label={t("companyLogin.usernameOrEmailLabel")}
                                  placeholder={
                                    // langTranslation?.usernameOrEmailHint
                                    t("companyLogin.usernameOrEmailHint")
                                  }
                                  id="email"
                                  name="email"
                                  autoComplete="off"
                                  required
                                />
                              </div>
                              <div className="col-12">
                                <Field
                                  component={PasswordInput}
                                  label={t("companyLogin.passwordLabel")}
                                  placeholder={t("companyLogin.passwordHint")}
                                  id="password"
                                  name="password"
                                  autoComplete="password"
                                  rightIcon={
                                    <i className="fa-solid fa-lock"></i>
                                  }
                                  required
                                />
                              </div>
                              <div className="text-start mt-2 ms-0">
                                <NavLink
                                  to={getPathPrefix() + "forgot-password"}
                                  className=""
                                >
                                  <small>
                                    {t("companyLogin.forgotPasswordText")}
                                  </small>
                                </NavLink>
                              </div>
                            </div>

                            <div className="text-center mb-2">
                              <PrimaryButton
                                type="submit"
                                title={t("CommonKeys.login_hyphen")}
                                icon="fa-solid fa-right-to-bracket"
                                disabled={!isValid || isSubmitting || !dirty}
                              />
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      }
    </>
  );
};

export default Login;
