import React from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const LeftPanel = () => {
  const location = useLocation();

  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const loginValue = useMemo(
    () => searchParams.get("type") ?? "1",
    [searchParams]
  );

  const [t] = useTranslation();
  return (
    <div className="col-lg-6 d-lg-block d-none left-panel">
      <div class="intro">
        <h2 className="text-app-white">
          {t("authenticationLeftPanel.welcomeToTitle")}
        </h2>
        <p className="text-app-white">
          {t("authenticationLeftPanel.welcomeToDescription")}
        </p>
        {/* <img src="/assets/images/ai-brain.png" alt="" class="img-fluid" /> */}
        {/* <img src="/assets/images/ai-brain.jpg" alt="" class="img-fluid" /> */}
        {loginValue === "1" && (
          <img
            src="/assets/images/Scandi-automation-gif.png"
            alt=""
            class="img-fluid"
          />
        )}
        {loginValue === "2" && (
          <img
            src="/assets/images/Scandi-automation-gif.png"
            alt=""
            class="img-fluid left-head"
          />
        )}
        {loginValue === "3" && (
          <img
            src="/assets/images/Scandi-automation-gif.png"
            alt=""
            class="img-fluid left-head-3"
          />
        )}
        {loginValue === "4" && (
          <img
            src="/assets/images/head_trimmed.png"
            alt=""
            class="img-fluid left-head-4"
          />
        )}
      </div>
    </div>
  );
};

export default LeftPanel;
