import React from "react";

function ManageSubscription() {
  return (
    <div>
      <div className="container-scroller">
        {/* <nav className="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
      <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
        <a className="navbar-brand brand-logo mr-5" href="index.html"><img src="/assets/images/AI_Cost_Control_logo.png" className="mr-2" alt="logo"/></a>
        <a className="navbar-brand brand-logo-mini" href="index.html"><img src="/assets/images/AI.png" alt="logo"/></a>
      </div>
      <div className="navbar-menu-wrapper d-flex align-items-center justify-content-end">
        <button className="navbar-toggler navbar-toggler align-self-center" type="button" data-toggle="minimize">
          <span><i className="fa-solid fa-bars-staggered"></i></span>
        </button>
        <ul className="navbar-nav navbar-nav-right">
          <li className="nav-item nav-profile dropdown">
            <a className="nav-link dropdown-toggle p-0" href="#" data-toggle="dropdown" id="profileDropdown">
              <div className="d-flex user-box align-items-center">
                <div className="user-info">
                    <p className="user-name mb-0">John Doe</p>
                    <p className="designattion mb-0">Admin</p>
                </div>
                <img src="/assets/images/avatars/avatar-1.png" className="user-img" alt="user avatar"/>
            </div>
            </a>
            <div className="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="profileDropdown">
              <a className="dropdown-item">
                <i className="fa-solid fa-user"></i>Update Profile
              </a>
              <a className="dropdown-item" href="reset-password.html">
                <i className="fa-solid fa-lock"></i>Change password
              </a>
              <a className="dropdown-item" href="login.html">
                <i className="fa-solid fa-right-from-bracket"></i>Logout
              </a>
            </div>
          </li>
        </ul>
        <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="offcanvas">
          <span><i className="fa-solid fa-bars-staggered"></i></span>
        </button>
      </div>
    </nav> */}
        {/* <!-- partial --> */}
        <div className="container-fluid page-body-wrapper p-0">
          {/* <nav className="sidebar sidebar-offcanvas" id="sidebar" data-simplebar="true">
        <ul className="nav">
          <li className="nav-item">
            <a className="nav-link" href="index.html">
              <i className="fa-solid fa-house menu-icon"></i>
              <span className="menu-title">Dashboard</span>
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" data-toggle="collapse" href="#ui-basic" aria-expanded="false" aria-controls="ui-basic">
              <i className="fa-solid fa-calendar-day menu-icon"></i>
              <span className="menu-title">Estimate</span>
              <i className="menu-arrow"></i>
            </a>
            <div className="collapse" id="ui-basic">
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <a className="nav-link" href="rule-engine.html"><img src="/assets/images/engine.svg" alt=""/>Rule Engine</a></li>
                <li className="nav-item"> <a className="nav-link" href="routing.html"><img src="/assets/images/routing.svg" alt=""/>Routing</a></li>
                <li className="nav-item"> <a className="nav-link" href="geographical-control.html"><img src="/assets/images/globe.svg" alt=""/>Geographical Control</a></li>
                <li className="nav-item"> <a className="nav-link" href="documents.html"><img src="/assets/images/document.svg" alt=""/>Document</a></li>
              </ul>
            </div>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#">
              <i className="fa-solid fa-clipboard-check menu-icon"></i>
              <span className="menu-title">Validation</span>
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#">
              <i className="fa-solid fa-file-lines menu-icon"></i>
              <span className="menu-title">Inspect Report</span>
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#">
              <i className="fa-solid fa-file-invoice menu-icon"></i>
              <span className="menu-title">E-Invoice</span>
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" data-toggle="collapse" href="#user" aria-expanded="false">
              <i className="fa-solid fa-users menu-icon"></i>
              <span className="menu-title">Company User</span>
              <i className="menu-arrow"></i>
            </a>
            <div className="collapse" id="user">
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <a className="nav-link" href="user-list.html"><img src="/assets/images/user.svg" alt=""/>User List</a></li>
                <li className="nav-item"> <a className="nav-link" href="create-user.html"><i className="fa-solid fa-user-group"></i>Create User</a></li>
              </ul>
            </div>
          </li>
          <li className="nav-item">
            <a className="nav-link" data-toggle="collapse" href="#company" aria-expanded="false">
              <i className="fa-solid fa-building menu-icon"></i>
              <span className="menu-title">Company</span>
              <i className="menu-arrow"></i>
            </a>
            <div className="collapse" id="company">
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <a className="nav-link" href="view-company.html"><i className="fa-solid fa-building"></i>View Company</a></li>
                <li className="nav-item"> <a className="nav-link" href="manage-subscription.html"><i className="fa-solid fa-money-bill"></i>Manage Subscription</a></li>
              </ul>
            </div>
          </li>
          <li className="nav-item">
            <a className="nav-link" data-toggle="collapse" href="#data-stream" aria-expanded="false">
              <i className="fa-solid fa-network-wired menu-icon"></i>
              <span className="menu-title">Data Stream</span>
              <i className="menu-arrow"></i>
            </a>
            <div className="collapse" id="data-stream">
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <a className="nav-link" href="#"><i className="fa-solid fa-arrow-right"></i>Data Stream Group</a></li>
                <li className="nav-item"> <a className="nav-link" href="#"><i className="fa-solid fa-arrow-right"></i>Data Stream Schema</a></li>
                <li className="nav-item"> <a className="nav-link" href="field-configuration.html"><i className="fa-solid fa-arrow-right"></i>Field Configuration</a></li>
              </ul>
            </div>
          </li>
          <li className="nav-item">
            <a className="nav-link" data-toggle="collapse" href="#help-support" aria-expanded="false">
              <i className="fa-solid fa-circle-info menu-icon"></i>
              <span className="menu-title">Help & Support</span>
              <i className="menu-arrow"></i>
            </a>
            <div className="collapse" id="help-support">
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <a className="nav-link" href="#"><i className="fa-solid fa-lightbulb"></i>Knowledge Base</a></li>
                <li className="nav-item"> <a className="nav-link" href="contact-aicc.html"><i className="fa-solid fa-square-phone"></i>Contact AICC</a></li>
              </ul>
            </div>
          </li>
        </ul>
        <div className="version-card">
          <h6>Release Version</h6>
          <h6 className="mb-0"><strong>AI 1.0.1</strong></h6>
     </div>
      </nav> */}
          {/* <!-- partial --> */}
          <div className="main-panel">
            <div className="content-wrapper">
              {/* <!-- breadcrumb-start --> */}
              <div className="page-breadcrumb d-none d-md-flex align-items-center mb-5">
                <div className="breadcrumb-title pe-3">Company</div>
                <div className="ps-3">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb mb-0 p-0">
                      <li className="breadcrumb-item">
                        <a href="#">
                          <i className="fa-solid fa-house"></i>
                        </a>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Manage Subscription
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
              {/* <!-- breadcrumb-end --> */}
              <div className="col-12">
                <div className="card main-card-table">
                  <div className="card-header">
                    <div className="row align-items-center">
                      <div className="col-12 col-md-4">
                        <div className="card-label d-flex">
                          <i className="fa-solid fa-money-bill me-3 text-primary fs-4"></i>{" "}
                          Manage Subscription
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body table-responsive">
                    <table className="table table-modern">
                      <thead>
                        <tr>
                          <th>Subscription ID</th>
                          <th>Active Date</th>
                          <th>Expiry Date</th>
                          <th>No Of Users</th>
                          <th>Api Access</th>
                          <th>Subscription Type</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>123</td>
                          <td>2023-04-18</td>
                          <td>2023-04-20</td>
                          <td>20</td>
                          <td>Yes</td>
                          <td>Yearly</td>
                          <td>
                            <span>
                              <a href="#">
                                <i className="fa-regular fa-eye"></i>
                              </a>
                            </span>
                            <span>
                              <a href="#">
                                <i className="fa-regular fa-address-card"></i>
                              </a>
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>123</td>
                          <td>2023-04-18</td>
                          <td>2023-04-20</td>
                          <td>20</td>
                          <td>No</td>
                          <td>Monthly</td>
                          <td>
                            <span>
                              <a href="#">
                                <i className="fa-regular fa-eye"></i>
                              </a>
                            </span>
                            <span>
                              <a href="#">
                                <i className="fa-regular fa-address-card"></i>
                              </a>
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>123</td>
                          <td>2023-04-18</td>
                          <td>2023-04-20</td>
                          <td>20</td>
                          <td>Yes</td>
                          <td>Yearly</td>
                          <td>
                            <span>
                              <a href="#">
                                <i className="fa-regular fa-eye"></i>
                              </a>
                            </span>
                            <span>
                              <a href="#">
                                <i className="fa-regular fa-address-card"></i>
                              </a>
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>123</td>
                          <td>2023-04-18</td>
                          <td>2023-04-20</td>
                          <td>20</td>
                          <td>No</td>
                          <td>Custom</td>
                          <td>
                            <span>
                              <a href="#">
                                <i className="fa-regular fa-eye"></i>
                              </a>
                            </span>
                            <span>
                              <a href="#">
                                <i className="fa-regular fa-address-card"></i>
                              </a>
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- content-wrapper ends --> */}
            {/* <!-- partial:partials/_footer.html --> */}
            <footer className="footer">
              <div className="d-sm-flex justify-content-center">
                <p className="mb-0">
                  Copyright © 2023{" "}
                  <a
                    rel="noreferrer"
                    style={{ textDecorationLine: "underline !important" }}
                    href="https://scandiautomation.com/"
                    target="_blank"
                  >
                    Scandi Automation
                  </a>
                  .
                </p>
              </div>
            </footer>
            {/* <!-- partial --> */}
          </div>
          {/* <!-- main-panel ends --> */}
        </div>
        {/* <!-- page-body-wrapper ends --> */}
      </div>
      {/* <!-- container-scroller --> */}
    </div>
  );
}

export default ManageSubscription;
