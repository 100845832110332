import React, { createContext, useContext, useRef } from "react";
import { IdleTimerProvider, useIdleTimer } from "react-idle-timer";
import { useNavigate } from "react-router-dom"; // If you're using React Router for navigation
import { getPathPrefix } from "../utils/coreUtils";
const IdleTimerContext = createContext();

export const useIdleTimerContext = () => useContext(IdleTimerContext);

export const IdleTimerProviderComponent = ({ children }) => {
  const navigate = useNavigate(); // If you're using React Router
  const idleTimerRef = useRef(null);

  const onIdle = () => {
    // Redirect to login page
    console.log("company User is Idle for 20 minute");
    localStorage.removeItem("authToken");
    localStorage.removeItem("companyUserId");
    navigate(getPathPrefix() + "login");
  };

  return (
    <IdleTimerProvider
      ref={idleTimerRef}
      timeout={20 * 60 * 1000} // 20 minutes
      onIdle={onIdle}
    >
      <IdleTimerContext.Provider value={idleTimerRef}>
        {children}
      </IdleTimerContext.Provider>
    </IdleTimerProvider>
  );
};
