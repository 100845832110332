import { useMemo } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ReactSelect from "react-select";
import { nameCapitalize } from "../../utils/coreUtils";
import BasicTextInput from "../Form/Inputs/common/BasicTextInput";
import CreatableSelect from "react-select/creatable";
import DynamicMessage from "./DynamicMessage";
import openApi from "../../services/apis/openApi";
import customFields from "../../services/apis/customFields";
import StorageManager from "../../features/library/utils/storageManager";
import { useParams } from "react-router-dom";
import { useQueryContext } from "../../features/selectedDataStream/context/RuleEndingProvider";

export const RULE_MESSAGE_TYPE = {
  INTERNAL: "INTERNAL",
  EXTERNAL: "EXTERNAL",
  EPOST: "EMAIL",
};

export default function CreateRuleSettingTab(props) {
  const params = useParams();
  const {
    settingState,
    setSettingState,
    rulesTypesData,
    getAllRouteUser,
    isEdit,
    viewOnly,
    created,
    errors,
    setErrors,
    updateError,
    updateErrorForInputs,
    ruleOverviewDropdown,
    setRuleOverviewDropdown,
    getNextPriorityForRules,
    tempPriority,
  } = props;
  const [t] = useTranslation();
  const [showDropDown, setShowDrop] = useState(false);

  const [groupData, setGroupData] = useState([]);
  const [configList, setConfigList] = useState([]);
  const [messageTypeSelection, setMessageTypeSelection] = useState("static");
  const [isDynamicMessageConstruction, setIsDynamicMessageConstruction] =
    useState(false);
  const selectedDataStream = StorageManager.getSelectedDataStream();

  const streamObj = selectedDataStream
    ? JSON.parse(selectedDataStream)
    : undefined;

  var rulesTypesList = rulesTypesData.sort(function (a, b) {
    return a.rulesOrder - b.rulesOrder;
  });

  const routeUserSelectOptions = useMemo(
    () => [
      {
        label: nameCapitalize("Select None"),
        value: null,
      },
      ...getAllRouteUser.map((e) => ({
        ...e,
        label: nameCapitalize(e.firstName) + " " + nameCapitalize(e.lastName),
        value: e.companyRouteUserId,
      })),
    ],
    [getAllRouteUser]
  );

  const manualRuleType = useMemo(
    () =>
      rulesTypesData
        .filter((e) => e.label.toLowerCase().includes("manual"))
        .map((e) => e.rulesTypeId),
    [rulesTypesData]
  );

  const onMessageChange = ({
    email,
    emailMessage,
    message,
    dynamicMessage,
    dynamicMessage1,
    emailDynamicMessage,
    message1,
    message2,
    message3,
    order,
    internalDynamic,
    isDynamic1,
    isDynamic2,
    isDynamic3,
    emailDynamic,
  }) => {
    setSettingState((prev) => ({
      ...prev,
      email: email ?? prev.email,
      emailMessage: emailMessage ?? prev.emailMessage,
      message: message ?? prev.message,
      dynamicMessage: dynamicMessage ?? prev.dynamicMessage,
      dynamicMessage1: dynamicMessage1 ?? prev.dynamicMessage1,
      emailDynamicMessage: emailDynamicMessage ?? prev.emailDynamicMessage,
      message1: message1 ?? prev.message1,
      message2: message2 ?? prev.message2,
      message3: message3 ?? prev.message3,
      order: order ?? prev.order,
      internalDynamic: internalDynamic ?? prev.internalDynamic,
      isDynamic1: isDynamic1 ?? prev.isDynamic1,
      isDynamic2: isDynamic2 ?? prev.isDynamic2,
      isDynamic3: isDynamic3 ?? prev.isDynamic3,
      emailDynamic: emailDynamic ?? prev.emailDynamic,
    }));
  };

  useEffect(() => {
    if (isEdit || viewOnly) {
      setShowDrop(manualRuleType.includes(settingState?.ruleTypeSelectId));
    } else {
      setShowDrop(settingState?.ruleTypeSelect?.toLowerCase() === "manual");
    }
  }, [isEdit, viewOnly, rulesTypesList, showDropDown, settingState, manualRuleType]);

  useEffect(() => {
    getCustomFieldsByDataStreamConfigId();
    getCompanyInformationByDomainName();
  }, []);

  async function getCompanyInformationByDomainName() {
    const response = await openApi.getCompanyInformationByDomainName({
      domainName: params?.companyDomain,
    });

    setIsDynamicMessageConstruction(response.data.isDynamicMessageConstruction);
  }

  async function getCustomFieldsByDataStreamConfigId() {
    try {
      const response = await customFields.getCustomFieldsByDataStreamConfigId({
        dataStreamConfigId: streamObj?.dataStreamConfigId,
      });
      setGroupData(response.data);
      getDataStreamConfig();
    } catch (error) { }
  }

  const getDataStreamConfig = async () => {
    try {
      const { data } = await openApi.getDataStreamConfig();

      if (typeof data !== "string") {
        setConfigList(data ?? []);
      } else {
        // setIsLoading(false);
      }
    } catch (error) {
      // setIsLoading(false);
    }
  };

  return (
    <div className="operator-area">
      <div className="setting-control mb-4">
        <h6 className="text-primary mb-3">Settings</h6>
        <div className="setting-control-operator">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <BasicTextInput
                label={t("createRuleEngine.settingsTab.ruleName.label")}
                isRequired={true}
                groupProps={{
                  controlId: "rule-name",
                  // className: "mb-3",
                }}
                inputProps={{
                  placeholder: t(
                    "createRuleEngine.settingsTab.ruleName.placeholder"
                  ),
                }}
                name="ruleName"
                value={settingState?.ruleName}
                onChange={(e) => {
                  updateErrorForInputs(
                    e.target.value,
                    undefined,
                    "ruleName",
                    "Please enter a rule name"
                  );
                  setSettingState((prev) => ({
                    ...prev,
                    ruleName: e.target.value,
                  }));
                }}
                onBlur={(e) => {
                  updateErrorForInputs(
                    e.target.value,
                    undefined,
                    "ruleName",
                    "Please enter a rule name"
                  );
                }}
                disabled={viewOnly}
                formLabelClass={{
                  className: "mt-2",
                }}
              />
              {errors.ruleName && (
                <span
                  style={{ height: "20px", fontSize: "13px" }}
                  className="text-danger mt-1"
                >
                  {errors.ruleName}
                </span>
              )}
            </div>
            <div className="col-lg-6 col-md-6">
              <label htmlFor="routing-user" className="form-label mt-2">
                {t("createRuleEngine.settingsTab.groupName.label")}
                <span style={{ color: "red" }}>*</span>
              </label>
              <CreatableSelect
                name="groupName"
                value={ruleOverviewDropdown.find(
                  (e) =>
                    e.label?.toLowerCase() ===
                    settingState?.groupName?.toLowerCase()
                )}
                options={ruleOverviewDropdown}
                onChange={(e) => {
                  updateErrorForInputs(
                    e.label,
                    undefined,
                    "groupName",
                    "Please enter a group name"
                  );
                  setSettingState((prev) => ({
                    ...prev,
                    groupName: e.label,
                  }));
                }}
                isDisabled={viewOnly}
                classNamePrefix="select"
              />
              {errors.groupName && (
                <span
                  style={{ height: "20px", fontSize: "13px" }}
                  className="text-danger mt-1"
                >
                  {errors.groupName}
                </span>
              )}
            </div>
            <div className="col-lg-6 col-md-6">
              <BasicTextInput
                type="number"
                label={t("createRuleEngine.settingsTab.priority.label")}
                // isRequired={true}
                groupProps={{
                  controlId: "priority",
                  // className: "mb-3",
                }}
                inputProps={{
                  placeholder: t(
                    "createRuleEngine.settingsTab.priority.placeholder"
                  ),
                  min: 1,
                  max: settingState?.maxPriority,
                }}
                name="priority"
                value={settingState?.priority}
                onChange={(e) => {
                  updateErrorForInputs(
                    e.target.value,
                    undefined,
                    "priority",
                    "Please enter a priority",
                    e.target.min,
                    e.target.max
                  );
                  setSettingState((prev) => ({
                    ...prev,
                    priority: e.target.value,
                  }));
                }}
                onBlur={(e) => {
                  updateErrorForInputs(
                    e.target.value,
                    undefined,
                    "priority",
                    "Please enter a priority",
                    e.target.min,
                    e.target.max
                  );
                }}
                // disabled={created ? true : viewOnly || !settingState.isActive}
                disabled={true}
                formLabelClass={{
                  className: "mt-3",
                }}
              />
              {errors.priority && (
                <span
                  style={{ height: "20px", fontSize: "13px" }}
                  className="text-danger mt-1"
                >
                  {errors.priority}
                </span>
              )}
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="switch mt-4">
                <label className="form-label">
                  {t("createRuleEngine.settingsTab.status.label")}
                </label>
                <input
                  type="checkbox"
                  id="isActive"
                  name="isActive"
                  disabled={viewOnly ? true : false}
                  checked={settingState.isActive}
                  onChange={(e) => {
                    if (e.target.checked === true) {
                      if (!isEdit) {
                        getNextPriorityForRules();
                      } else {
                        if (tempPriority !== -1) {
                          setSettingState((prev) => ({
                            ...prev,
                            priority: tempPriority,
                          }));
                        } else {
                          getNextPriorityForRules();
                        }
                      }
                    } else if (e.target.checked === false) {
                      setSettingState((prev) => ({
                        ...prev,
                        priority: "-1",
                      }));
                    }
                    setSettingState((prev) => ({
                      ...prev,
                      isActive: e.target.checked,
                    }));
                  }}
                />
              </div>
            </div>
            <div className="col-12">
              <label htmlFor="routing-user" className="form-label mt-3">
                {t("createRuleEngine.settingsTab.routingUser.ruleType")}{" "}
                <span style={{ color: "red" }}>*</span>
              </label>
              <br />
              {rulesTypesList?.map((item, index) => {
                return (
                  item.isActive && (
                    <Form.Check
                      key={index}
                      inline
                      // label={t("createRuleEngine.settingsTab.ruleTypes.visual")}
                      label={item?.label}
                      name="group1"
                      type={"radio"}
                      id={item?.label}
                      value={item?.label}
                      checked={
                        settingState?.ruleTypeSelectId === item?.rulesTypeId ||
                        (item.label.toLowerCase() === "manual" &&
                          manualRuleType.includes(
                            settingState?.ruleTypeSelectId
                          ))
                      }
                      onChange={(e) => {
                        // if (item.label.toLowerCase() !== "manual") {
                        // }
                        setSettingState((prev) => ({
                          ...prev,
                          routingUserId: null,
                        }));
                        setSettingState((prev) => ({
                          ...prev,
                          ruleTypeSelect: e.target.value,
                          ruleTypeSelectId: item?.rulesTypeId,
                        }));
                      }}
                      disabled={viewOnly}
                    />
                  )
                );
              })}
            </div>
            {showDropDown && (
              <div className="col-12">
                <label htmlFor="routing-user" className="form-label mt-3">
                  {t("createRuleEngine.settingsTab.routingUser.label")}
                </label>
                <ReactSelect
                  classNamePrefix="select"
                  className="mb-3"
                  id="routing-user"
                  placeholder={t(
                    "createRuleEngine.settingsTab.routingUser.placeholder"
                  )}
                  noOptionsMessage={() =>
                    t(
                      "createRuleEngine.settingsTab.routingUser.noOptionsMessage"
                    )
                  }
                  options={routeUserSelectOptions}
                  value={routeUserSelectOptions.find(
                    (e) => e.value === settingState?.routingUserId
                  )}
                  onChange={(e) => {
                    setSettingState((prev) => ({
                      ...prev,
                      routingUserId: e?.value,
                    }));
                    if (e?.value === null) {
                      setSettingState((prev) => ({
                        ...prev,
                        ruleTypeSelectId: rulesTypesData?.find(
                          (elm) => elm?.label?.toLowerCase() === "manual"
                        ).rulesTypeId,
                      }));
                    }
                  }}
                  isDisabled={viewOnly}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="setting-control mt-4">
        <h6 className="text-primary mb-3">
          {t("createRuleEngine.settingsTab.messageOptions.title")}
          <span style={{ color: "red" }}> *</span>
        </h6>

        <div className="setting-control-operator">
          <div className="row">
            <nav>
              <div
                className="nav nav-tabs border-0"
                id="nav-tab"
                role="tablist"
              >
                <div
                  className="set active"
                  id="internal-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#internal"
                  type="button"
                  role="tab"
                  onClick={() => {
                    setSettingState((prev) => ({
                      ...prev,
                      messageType: RULE_MESSAGE_TYPE.INTERNAL,
                    }));
                  }}
                >
                  <Form.Check
                    inline
                    label={t(
                      "createRuleEngine.settingsTab.messageOptions.internal"
                    )}
                    name="group2"
                    type={"radio"}
                    id={`msgOpt-1`}
                    value="internal"
                    disabled={viewOnly}
                    checked={
                      settingState?.messageType === RULE_MESSAGE_TYPE.INTERNAL
                    }
                    onChange={() => {
                      setSettingState((prev) => ({
                        ...prev,
                        messageType: RULE_MESSAGE_TYPE.INTERNAL,
                      }));
                    }}
                  />
                </div>
                <div
                  className="set"
                  id="external-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#external"
                  type="button"
                  role="tab"
                  onClick={() => {
                    setSettingState((prev) => ({
                      ...prev,
                      messageType: RULE_MESSAGE_TYPE.EXTERNAL,
                    }));
                  }}
                >
                  <Form.Check
                    inline
                    label={t(
                      "createRuleEngine.settingsTab.messageOptions.external"
                    )}
                    name="group2"
                    type={"radio"}
                    id={`msgOpt-2`}
                    value="external"
                    disabled={viewOnly}
                    checked={
                      settingState?.messageType === RULE_MESSAGE_TYPE.EXTERNAL
                    }
                    onChange={() => {
                      setSettingState((prev) => ({
                        ...prev,
                        messageType: RULE_MESSAGE_TYPE.EXTERNAL,
                      }));
                    }}
                  />
                </div>
                <div
                  className="set"
                  id="email-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#email"
                  type="button"
                  role="tab"
                  onClick={() => {
                    setSettingState((prev) => ({
                      ...prev,
                      messageType: RULE_MESSAGE_TYPE.EPOST,
                    }));
                  }}
                >
                  <Form.Check
                    inline
                    label={t(
                      "createRuleEngine.settingsTab.messageOptions.email"
                    )}
                    name="group2"
                    type={"radio"}
                    id={`msgOpt-3`}
                    value="email"
                    disabled={viewOnly}
                    checked={
                      settingState?.messageType === RULE_MESSAGE_TYPE.EPOST
                    }
                    onChange={() => {
                      setSettingState((prev) => ({
                        ...prev,
                        messageType: RULE_MESSAGE_TYPE.EPOST,
                      }));
                    }}
                  />
                </div>
              </div>
            </nav>
            <div className="tab-content" id="nav-tabContent">
              <div
                className={
                  "tab-pane fade" +
                  (settingState.messageType === RULE_MESSAGE_TYPE.INTERNAL
                    ? " show active"
                    : "")
                }
                id="internal"
                role="tabpanel"
                aria-labelledby="internal-tab"
              >
                <div className="col-lg-8 col-md-8">
                  <DynamicMessage
                    id="Internal"
                    isDynamicMessageConstruction={isDynamicMessageConstruction}
                    viewOnly={viewOnly}
                    configList={configList}
                    groupData={groupData}
                    placeholder={t(
                      "createRuleEngine.settingsTab.messageOptions.internalMessage.messagePlaceholder"
                    )}
                    value={settingState.dynamicMessage}
                    messageTypeSelection={settingState.internalDynamic}
                    staticMessage={settingState.message}
                    onStaticMessageChange={(messageText) => {
                      onMessageChange({
                        message: messageText,
                      });
                    }}
                    onMessageTypeChange={(messageType) => {
                      // setMessageTypeSelection(messageType);
                      onMessageChange({
                        internalDynamic: messageType,
                      });
                    }}
                    onDynamicMessageChange={(messageText) => {
                      onMessageChange({
                        dynamicMessage: messageText,
                      });
                    }}
                  />
                </div>
              </div>
              <div
                className={
                  "tab-pane fade" +
                  (settingState.messageType === RULE_MESSAGE_TYPE.EXTERNAL
                    ? " show active"
                    : "")
                }
                id="external"
                role="tabpanel"
                aria-labelledby="external-tab"
              >
                <div className="col-lg-12 col-md-12">
                  <div className="row">
                    <div className="col-lg-8 col-md-8">
                      <DynamicMessage
                        id="External"
                        isDynamicMessageConstruction={
                          isDynamicMessageConstruction
                        }
                        viewOnly={viewOnly}
                        configList={configList}
                        groupData={groupData}
                        placeholder={t(
                          "createRuleEngine.settingsTab.messageOptions.externalMessage.message1Placeholder"
                        )}
                        value={settingState.dynamicMessage1}
                        messageTypeSelection={settingState.isDynamic1}
                        staticMessage={settingState.message1}
                        onMessageTypeChange={(messageType) => {
                          // setMessageTypeSelection(messageType);
                          onMessageChange({
                            isDynamic1: messageType,
                          });
                        }}
                        onStaticMessageChange={(messageText) => {
                          onMessageChange({
                            message1: messageText,
                            order: 1,
                          });
                        }}
                        onDynamicMessageChange={(messageText) => {
                          onMessageChange({
                            dynamicMessage1: messageText,
                            order: 1,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
                  <div
                    className={
                      "tab-pane fade" +
                      (settingState.messageType === RULE_MESSAGE_TYPE.EPOST
                        ? " show active"
                        : "")
                    }
                    id="email"
                    role="tabpanel"
                    aria-labelledby="email-tab"
                  >
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <BasicTextInput
                          label={t(
                            "createRuleEngine.settingsTab.messageOptions.emailMessage.emailLabel"
                          )}
                          // isRequired
                          groupProps={{
                            controlId: "email-address",
                            className: "mb-3",
                          }}
                          inputProps={{
                            placeholder: t(
                              "createRuleEngine.settingsTab.messageOptions.emailMessage.emailPlaceholder"
                            ),
                          }}
                          disabled={viewOnly}
                          value={settingState?.email}
                          onChange={(e) => {
                            onMessageChange({
                              email: e.target.value,
                            });
                          }}
                        />
                        <div className="col-lg-12 col-md-12">
                          <div className="row">
                          <DynamicMessage
                              id="Email"
                              isDynamicMessageConstruction={
                                isDynamicMessageConstruction
                              }
                              viewOnly={viewOnly}
                              configList={configList}
                              groupData={groupData}
                              placeholder={t(
                                  "createRuleEngine.settingsTab.messageOptions.emailMessage.messagePlaceholder"
                              )}
                              value={settingState.emailDynamicMessage}
                              messageTypeSelection={settingState.emailDynamic}
                              staticMessage={settingState.emailMessage}
                              onMessageTypeChange={(messageType) => {
                                // setMessageTypeSelection(messageType);
                                onMessageChange({
                                  emailDynamic: messageType,
                                });
                              }}
                              onStaticMessageChange={(messageText) => {
                                onMessageChange({
                                  emailMessage: messageText,
                                });
                              }}
                              onDynamicMessageChange={(messageText) => {
                                onMessageChange({
                                  emailDynamicMessage: messageText,
                                });
                              }}
                          />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
