import React, { useEffect, useMemo } from "react";
import { useState } from "react";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import ReactSelect from "react-select";
import { toast } from "react-toastify";
import AiccBreadCrumb from "../../components/common/AiccBreadCrumb";
import CenterLoading from "../../components/common/CenterLoading";
import PrimaryButton from "../../components/Form/Buttons/PrimaryButton";
import BasicTextInput from "../../components/Form/Inputs/common/BasicTextInput";
import ConfirmActivateModal from "../../components/Modals/ConfirmActivateModal";
import CustomModal from "../../components/Modals/CustomModal";
import rulesApi from "../../services/apis/rulesApi";
import {
  CRUD_RULE_ENGINE_TYPES,
  getPathPrefix,
  nameCapitalize,
  reorder,
} from "../../utils/coreUtils";
import ToastUtils from "../../utils/ToastUtils";
import StorageManager from "../library/utils/storageManager";
import "./styles.css";
import "./styles.scss";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ruleEngine from "../../services/apis/ruleEngine";

const RuleEngVersionOverview = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const viewStatus = searchParams.get("a");
  const [ruleOverviewList, setRuleOverviewList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [versionToDelete, setVersionToDelete] = useState();
  const [versionOverview, setVersionOverview] = useState();
  const [ruleOverviewDropdown, setRuleOverviewDropdown] = useState([]);
  const [selectedRule, setSelectedRule] = useState();
  const [selectedRuleType, setSelectedRuleType] = useState(-1);
  const [rulesTypesData, setRulesTypesData] = useState([]);
  const [search, setSearch] = useState("");
  const [priority, setPriority] = useState("");
  const [maxPriority, setMaxPriority] = useState("");
  const [showPriority, setShowPriority] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [errors, setErrors] = useState({});
  const [prioritySame, setPrioritySame] = useState(false);
  const [selectedRow, setSelectedRow] = useState({ row: "", checked: "" });
  const [versionsDetails, setVersionsDetails] = useState();
  const [ruleQueryString, setRuleQueryString] = useState("");
  const [showQuery, setShowQuery] = useState(false);
  const [changingStatus, setChangingStatus] = useState(false);

  const [t, i18n] = useTranslation();
  const selectedDataStream = StorageManager.getSelectedDataStream();

  const streamObj = selectedDataStream
    ? JSON.parse(selectedDataStream)
    : undefined;

  const customStyles = {
    headCells: {
      style: {
        fontSize: "14px",
        padding: "0.75rem",
        verticalAlign: "middle",
        color: "#004e5c",
        fontWeight: 800,
      },
    },
    cells: {
      style: {
        fontSize: "14px",
        padding: "0.75rem",
        verticalAlign: "middle",
      },
    },
  };

  useEffect(() => {
    getAllRules();
    getAllRuleTypes();
    getAllGroupByName();
  }, []);

  async function getAllRules() {
    // setIsLoading(true);
    try {
      const response = await rulesApi.getAllRulesByDSConfigVersionId({
        dsConfigVersionId: params?.versionId,
        // isActive: true,
        // isDeleted: true,
      });
      let getNewResponse = response?.data?.sort(function (a, b) {
        const aPriority = a.priority === -1 ? Number.MAX_VALUE : a.priority;
        const bPriority = b.priority === -1 ? Number.MAX_VALUE : b.priority;

        return aPriority - bPriority;
      });
      // console.log(JSON.stringify(getNewResponse.map(e => e.queryString), null, 2));
      setRuleOverviewList(getNewResponse);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
    // setIsLoading(false);
  }

  async function getAllGroupByName() {
    // setIsLoading(true);
    try {
      const response = await rulesApi.getGroupNameByGroup({
        dsConfigVersionId: params?.versionId,
      });
      setRuleOverviewDropdown(response.data);
    } catch (error) {
      console.error(error);
    }
    // setIsLoading(false);
  }

  async function getAllRuleTypes() {
    setIsLoading(true);
    try {
      const response = await rulesApi.getAllRulesType();
      var rulesTypesList = response?.data.sort(function (a, b) {
        return a.rulesOrder - b.rulesOrder;
      });
      // let takeObj = response?.data?.findIndex(
      //   (ruleTypes) => ruleTypes?.label.toLowerCase() === "manual"
      // );

      // if (takeObj > -1) {
      //   const obj = response?.data[takeObj];
      //   let tempRouting = {
      //     isActive: true,
      //     isDeleted: false,
      //     rulesOrder: obj.rulesOrder + 1,
      //     rulesTypeId: obj.rulesTypeId,
      //     label: obj.label + " " + "Routing",
      //   };
      //   rulesTypesList.splice(takeObj + 1, 0, tempRouting);
      // }
      setRulesTypesData(rulesTypesList);
      await getVersionsDetails();
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  }

  // for get version info by id
  const getVersionsDetails = async () => {
    try {
      const response = await ruleEngine.getDSConfigVersionById({
        dsConfigVersionId: params?.versionId,
      });
      setVersionsDetails(response?.data);
    } catch (error) {
      setIsLoading(false);
    }
  };

  async function getNextPriorityForRules() {
    // setIsLoading(true);
    try {
      const response = await rulesApi.getNextPriorityForRules({
        dsConfigVersionId: params?.versionId,
      });
      setPriority(response?.data?.nextPriority);
      setMaxPriority(response?.data?.nextPriority);
    } catch (error) {
      console.error(error);
    }
    // setIsLoading(false);
  }

  const columns = useMemo(
    () => [
      {
        name: t("versionOverview.columns.ruleName"),
        // selector: (row) => nameCapitalize(row.name),
        selector: (row) => {
          return (
            <OverlayTrigger
              placement="auto"
              overlay={(props) => (
                <Tooltip id="button-tooltip" {...props}>
                  {nameCapitalize(row.name)}
                </Tooltip>
              )}
            >
              <a
                className={[
                  "version-name-text",
                  viewStatus === "true" ? "" : "active",
                ].join(" ")}
                style={{
                  textDecoration:
                    viewStatus === "true" ? "" : "underline !important",
                }}
                onClick={() => {
                  if (viewStatus === "false") {
                    navigate(
                      getPathPrefix(
                        `selected-datastream/rule-engine/versions/${params?.versionId}/rule/${row.rulesId}?t=${CRUD_RULE_ENGINE_TYPES.EDIT}`
                      )
                    );
                  }
                }}
              >
                {nameCapitalize(row.name)}
              </a>
            </OverlayTrigger>
          );
        },
        sortable: true,
        minWidth: "130px",
        center: true,
      },
      {
        name: t("versionOverview.columns.priority"),
        selector: (row) =>
          row?.priority === null
            ? "N/A"
            : row?.priority === -1
            ? "N/A"
            : row?.priority,
        sortable: true,
        minWidth: "100px",
        center: true,
      },
      ...(rulesTypesData ?? []).map((item, index) => {
        return {
          name: item.label,
          selector: (row) => {
            return row.rulesTypeId === item.rulesTypeId
              ? t("CommonKeys.yes")
              : t("CommonKeys.no");
          },
          // sortable: true,
          minWidth: "170px",
          center: true,
        };
      }),
      {
        name: t("versionOverview.columns.editBy"),
        selector: (row) =>
          row?.updatedBy === null
            ? nameCapitalize(row.createdBy)
            : nameCapitalize(row.updatedBy),
        sortable: true,
        minWidth: "150px",
        center: true,
      },
      {
        name: t("versionOverview.columns.status"),
        cell: (row) => (
          <div className="switch mt-1">
            <input
              type="checkbox"
              id="status"
              name="status"
              checked={row.isActive}
              disabled={viewStatus === "true" || changingStatus}
              style={{
                opacity: changingStatus ? 0.5 : 1,
              }}
              onChange={(e) => {
                if (changingStatus) {
                  return;
                }
                // subStatusHandler(row, e.target.checked);
                selectedRow.row = row;
                setSelectedRow({ ...selectedRow });
                if (e.target.checked === true) {
                  setShowPriority(true);
                  selectedRow.checked = e.target.checked;
                  setSelectedRow({ ...selectedRow });
                  getNextPriorityForRules();
                } else {
                  subStatusHandler(row, e.target.checked);
                  selectedRow.checked = e.target.checked;
                  setSelectedRow({ ...selectedRow });
                }
                setPriority("");
              }}
            />
          </div>
        ),
        minWidth: "70px",
        center: true,
      },
      {
        name: t("versionOverview.columns.actions"),
        center: true,
        minWidth: "170px",
        cell: (row) => (
          <div className="row g-0 gap-2">
            {/* <span
              className="w-auto cursor-pointer action-icon"
              onClick={() => {
                alert(row.rulesId);
              }}
            >
              <i className="fa-regular fa-copy"></i>
            </span> */}
            <span
              className="w-auto cursor-pointer action-icon"
              onClick={() => {
                if (viewStatus !== "true") {
                  navigate(
                    getPathPrefix(
                      `selected-datastream/rule-engine/versions/${params?.versionId}/rule/${row.rulesId}?t=${CRUD_RULE_ENGINE_TYPES.EDIT}`
                    )
                  );
                }
              }}
              style={{ opacity: viewStatus === "true" ? 0.5 : "" }}
            >
              <i className="fa-regular fa-edit"></i>
            </span>
            <span
              className="w-auto cursor-pointer action-icon"
              onClick={() => {
                navigate(
                  getPathPrefix(
                    `selected-datastream/rule-engine/versions/${params?.versionId}/rule/${row.rulesId}?t=${CRUD_RULE_ENGINE_TYPES.VIEW}`
                  )
                );
              }}
            >
              <i className="fa-regular fa-eye"></i>
            </span>

            <span className="w-auto action-icon">
              <a
                className="cursor-pointer"
                onClick={() => {
                  setRuleQueryString(row?.queryString);
                  setShowQuery(row);
                }}
              >
                <i class="fa-solid fa-database"></i>
              </a>
            </span>

            <span className="w-auto action-icon">
              <a
                className="cursor-pointer"
                onClick={() => {
                  if (viewStatus !== "true") {
                    setShowDelete(true);
                    setVersionToDelete(row);
                  }
                }}
                style={{ opacity: viewStatus === "true" ? 0.5 : "" }}
              >
                <i className="fa-regular fa-trash-can"></i>
              </a>
            </span>
          </div>
        ),
      },
    ],
    [rulesTypesData, ruleOverviewList, viewStatus, params, changingStatus]
  );

  const deleteHandler = async (id) => {
    try {
      const response = await rulesApi.deleteRulesById({
        rulesId: id,
      });
      if (response.data.status != 200) {
        ToastUtils.serverError(t(`statusCodes.${response?.data?.code}`));
      } else {
        toast.success(t(`statusCodes.${response?.data?.code}`));
      }
      getAllRules();
    } catch (error) {}
  };

  const subStatusHandler = async (row, isChecked, isOverrite) => {
    setVersionOverview(row);
    //  let createRequest = {
    //    isDeleted: false,
    //    isActive: isChecked,
    //    rulesId: row.rulesId,
    //  };

    //  if (typeof isOverrite === "boolean" && isOverrite) {
    //    createRequest["isOverrite"] = isOverrite;
    //  }

    setChangingStatus(true);

    try {
      const response = await rulesApi.statusChangeForRules({
        rulesId: row.rulesId,
      });
      if (response.data.status != 200) {
        ToastUtils.serverError(t(`statusCodes.${response?.data?.code}`));
      } else {
        toast.success(t(`statusCodes.${response?.data?.code}`));
        await getAllRules();
      }
    } catch (error) {
      //  if (error.response.data.isDSConfigVersionActiveAlready) {
      //    setAlreadyActive(error.response.data.isDSConfigVersionActiveAlready);
      //  }
    }
    setChangingStatus(false);
  };

  const routeUserSelectOptions = useMemo(
    () => [
      {
        label: "Show All",
        value: "",
      },
      ...ruleOverviewDropdown.map((e, index) => ({
        label: nameCapitalize(e),
        value: index,
      })),
    ],
    [ruleOverviewDropdown]
  );
  const routeUserSelectedOptions = useMemo(
    () => [
      {
        label: " Show All",
        value: -1,
      },
      ...rulesTypesData.map((item, index) => ({
        label: nameCapitalize(item.label),
        value: item.rulesTypeId,
      })),
    ],
    [rulesTypesData]
  );

  const filteredRuleItems = ruleOverviewList.filter(
    (item) =>
      (search?.trim()
        ? item.name.toLowerCase().includes(search.toLowerCase())
        : true) &&
      (selectedRule
        ? item.groupName.toLowerCase().includes(selectedRule?.toLowerCase())
        : true) &&
      (selectedRuleType === -1 ? true : item.rulesTypeId === selectedRuleType)
  );

  // console.log("selectedRuleType", selectedRuleType);

  const filteredRuleByGroupItems = ruleOverviewList.filter(
    (item) =>
      item.groupName &&
      item.groupName.toLowerCase().includes(selectedRule?.toLowerCase())
  );
  // const fiteredRulesByData = ruleOverviewList.filter(
  //   (item)=> item.label&& item.label.toLowerCase().includes(selectedRule?.toLowerCase())
  // );

  const onSavePriorityModal = async (isOverrite) => {
    const createRequest = {
      dsConfigVersionId: params?.versionId,
      isDeleted: false,
      name: selectedRow?.row?.name,
      isActive: true,
      routingUserId: selectedRow?.row?.routingUserId
        ? selectedRow?.row?.routingUserId
        : 0,
      rulesId: selectedRow?.row?.rulesId,
      groupName: selectedRow?.row?.groupName,
      priority: priority,
      rulesTypeId: selectedRow?.row?.ruleTypeSelectId,
    };

    setChangingStatus(true);

    // for a change as we are facing issue while updating priority, we are calling below api to reset all priority at once.
    if (typeof isOverrite === "boolean" && isOverrite) {
      createRequest["isOverrite"] = isOverrite;

      // find the index for source whose priority will be set
      const sourceIndex = filteredRuleItems.findIndex(
        (e) => e.rulesId === selectedRow?.row?.rulesId
      );

      if (sourceIndex > -1) {
        // set destination to the priority - 1
        const destinationIndex = parseInt(priority) - 1;

        // update it in filtered rule items so we can update and avoid removing it from the array that will be sent on server.
        filteredRuleItems[sourceIndex].priority = parseInt(priority);
        filteredRuleItems[sourceIndex].isActive = true;

        // reorder list
        const items = reorder(filteredRuleItems, sourceIndex, destinationIndex);

        // remove elements which has -1 priority
        const filteredByNotMinus1 = items.filter((e) => e.priority != -1);

        try {
          // and save by calling api.
          await rulesApi.updatePriorityForRules(
            filteredByNotMinus1.map((e, index) => ({
              rulesId: e.rulesId,
              priority: index + 1,
              isActive: true,
            }))
          );

          /* if (response.data.status == 200) {
            toast.success(t(`statusCodes.${response.data.code}`));
          } else if (response.data.code) {
            ToastUtils.serverError(t(`statusCodes.${response.data.code}`));
          } */
          setShowPriority(false);
        } catch (error) {
          console.log(error);
        }
      }
    }

    // even if priority has been changed, we need to change status to true so for that reason we are calling this api even if priority has been changed.

    try {
      const response = await rulesApi.saveOrUpdateRules(createRequest);
      if (response.status === 200) {
        // subStatusHandler(selectedRow.row, selectedRow.checked);
        if (response.data.status != 200) {
          ToastUtils.serverError(t(`statusCodes.${response?.data?.code}`));
        } else {
          toast.success(t(`statusCodes.${response?.data?.code}`));
        }
        setShowPriority(false);
        await getAllRules();
      }
    } catch (error) {
      if (error.response.data.isDSConfigVersionPriorityAlready) {
        setPrioritySame(error.response.data.isDSConfigVersionPriorityAlready);
      } else if (error.response.data.status != 200) {
        ToastUtils.serverError(t(`statusCodes.${error.response.data.code}`));
      }
      // toast.error(error.response?.data);
    }

    setChangingStatus(false);
  };

  //for error handling
  const updateError = (key, value) => {
    setErrors((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  const updateErrorForInputs = (
    inputValue,
    minValue,
    inputKey,
    error,
    min,
    max
  ) => {
    let msg = "";

    if (!inputValue) {
      updateError(inputKey, error);
      setSubmitDisabled(true);
    } else if (min && parseInt(inputValue) < parseInt(min)) {
      // msg = `${inputKey} must be at least ${min} characters long.`;
      // msg = `Priority should be greater than 0 and less then or equal to ${max}`;
      msg = t("versionsForm.errorMessage.priorityRangeError", {
        max,
      });
      updateError(inputKey, msg);
      setSubmitDisabled(true);
    } else if (max && parseInt(inputValue) > parseInt(max)) {
      // msg = `${inputKey} is more than ${max} characters long.`;
      // msg = `Priority should be greater than 0 and less then or equal to ${max}`;
      msg = t("versionsForm.errorMessage.priorityRangeError", {
        max,
      });
      updateError(inputKey, msg);
      setSubmitDisabled(true);
    } else {
      updateError(inputKey, undefined);
      setSubmitDisabled(false);
    }
  };

  return (
    <>
      <CustomModal
        size={"xl"}
        show={showQuery}
        handleClose={() => setShowQuery(false)}
        body={ruleQueryString}
        centered
        okTitle={t("CommonKeys.okButtonTitle")}
        onOk={() => setShowQuery(false)}
      />

      <CustomModal
        show={showDelete}
        centered
        noHeader
        body={
          <h5
            className="modal-title text-center w-100"
            style={{ color: "#004e5c" }}
          >
            {t("versionOverview.delete")} <b>{versionToDelete?.name}</b>{" "}
            {t("versionOverview.rule")} ?
          </h5>
        }
        okTitle={t("CommonKeys.yes")}
        cancelTitle={t("CommonKeys.no")}
        centerFooter
        onOk={() => {
          deleteHandler(versionToDelete?.rulesId);
          setShowDelete(false);
        }}
        onCancel={() => {
          setShowDelete(false);
        }}
      />
      <CustomModal
        show={showPriority}
        centered
        noHeader
        body={
          <div className="col-lg-12 col-md-12">
            <BasicTextInput
              type="number"
              label={t("createRuleEngine.settingsTab.priority.label")}
              isRequired={true}
              groupProps={{
                controlId: "priority",
                // className: "mb-3",
              }}
              inputProps={{
                placeholder: t(
                  "createRuleEngine.settingsTab.priority.placeholder"
                ),
                min: 1,
                max: maxPriority,
              }}
              name="priority"
              value={priority}
              onChange={(e) => {
                updateErrorForInputs(
                  e.target.value,
                  undefined,
                  "priority",
                  "Please enter a priority",
                  e.target.min,
                  e.target.max
                );
                setPriority(e.target.value);
              }}
              formLabelClass={{
                className: "mt-3",
              }}
              onBlur={(e) => {
                updateErrorForInputs(
                  e.target.value,
                  undefined,
                  "priority",
                  "Please enter a priority",
                  e.target.min,
                  e.target.max
                );
              }}
            />
            {errors.priority && (
              <span
                style={{ height: "20px", fontSize: "13px" }}
                className="text-danger mt-1"
              >
                {errors.priority}
              </span>
            )}
          </div>
        }
        okTitle={t("CommonKeys.saveButtonTitle")}
        cancelTitle={t("CommonKeys.cancelButtonTitle")}
        centerFooter
        submitDisabled={submitDisabled}
        onOk={() => {
          onSavePriorityModal();
        }}
        onCancel={() => {
          setShowPriority(false);
        }}
      />
      <ConfirmActivateModal
        show={prioritySame}
        onOk={() => {
          onSavePriorityModal(true);
          setPrioritySame(false);
        }}
        onClose={() => {
          setPrioritySame(false);
        }}
        messageTitles={t("createRuleEngine.modalErrorMessage")}
      />
      <div className="container-scroller list-rule-engin-overview">
        {/* <!-- partial --> */}
        <div className="container-fluid page-body-wrapper p-0">
          {/* <!-- partial --> */}
          <div className="main-panel">
            <div className="content-wrapper">
              {/* <!-- breadcrumb-start --> */}
              <AiccBreadCrumb
                homeLink={getPathPrefix("dashboard")}
                title={streamObj?.customDataStreamName}
                items={t("ruleEngineOverview.breadCrumb.items", {
                  returnObjects: true,
                })}
              />
              {/* <!-- breadcrumb-end --> */}
              <div className="col-12">
                <div className="card main-card-table">
                  <div className="card-header">
                    <div className="row align-items-center">
                      <div className="col-12 col-md-12">
                        <div className="card-label">
                          <img
                            src="/assets/images/engine.svg"
                            alt=""
                            className="me-2"
                          />{" "}
                          {t("versionOverview.ruleOverview")} -{" "}
                          {streamObj?.customDataStreamName} - (
                          {versionsDetails?.versionName})
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="form">
                      <div className="row">
                        <div className="col-lg-4 col-md-4">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder={t("versionOverview.searchHint")}
                              value={search}
                              style={{ fontSize: "1rem" }}
                              onChange={(e) => {
                                setSearch(e.target.value);
                                if (e.target.value === "") {
                                  setResetPaginationToggle(
                                    !resetPaginationToggle
                                  );
                                }
                              }}
                            />
                          </div>
                        </div>
                        <div
                          className="col-lg-4 col-md-4"
                          style={{
                            position: "relative",
                            zIndex: 3,
                          }}
                        >
                          {/* <label className="mb-2 form-label">
                            {t("versionOverview.selectRule")}
                          </label> */}
                          <ReactSelect
                            classNamePrefix="select"
                            value={routeUserSelectOptions.find(
                              (e) => e.label === selectedRule
                            )}
                            options={routeUserSelectOptions}
                            isOptionSelected={(option, selectValue) => {
                              return option.label === selectedRule;
                            }}
                            onChange={(e) => {
                              if (e.value === "" && e.label === "Show All") {
                                getAllRules();
                                setSelectedRule(undefined);
                              } else {
                                setSelectedRule(e.label);
                              }
                            }}
                            placeholder={t("versionOverview.selectGroup")}
                          />
                        </div>
                        <div
                          className="col-lg-4 col-md-4"
                          style={{
                            position: "relative",
                            zIndex: 3,
                          }}
                        >
                          {/* <label className="mb-2 form-label">
                            {t("versionOverview.selectRule")}
                          </label> */}
                          <ReactSelect
                            classNamePrefix="select"
                            value={routeUserSelectedOptions.find(
                              (e) => e.label === selectedRule
                            )}
                            options={routeUserSelectedOptions}
                            isOptionSelected={(option, selectValue) => {
                              return option.label === selectedRule;
                            }}
                            onChange={(e) => {
                              setSelectedRuleType(e.value);
                            }}
                            placeholder={t("versionOverview.selectRuleType")}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="data-table">
                      <DataTable
                        responsive
                        persistTableHead
                        keyField={"id"}
                        progressPending={isLoading}
                        progressComponent={
                          <CenterLoading minHeight={"150px"} />
                        }
                        columns={columns}
                        data={
                          filteredRuleItems
                          /* selectedRule
                            ? filteredRuleByGroupItems
                            : filteredRuleItems */
                          // ? fiteredRulesByData
                          // : filteredRuleItems
                        }
                        paginationResetDefaultPage={resetPaginationToggle}
                        pagination
                        customStyles={customStyles}
                      />
                    </div>
                    <div className="text-end mt-3">
                      <div className="d-block d-md-flex justify-content-end">
                        <PrimaryButton
                          title={t("CommonKeys.backButtonTitle")}
                          icon="fa-solid fa-arrow-left"
                          onClick={() => {
                            navigate(
                              getPathPrefix(
                                "selected-datastream/rule-engine/versions"
                              )
                            );
                          }}
                          className="primaryButton"
                        />
                        <PrimaryButton
                          title={t("versionOverview.alterPriority")}
                          // title={"Alter Priority"}
                          onClick={() => {
                            navigate(
                              getPathPrefix(
                                `selected-datastream/rule-engine/versions/${params?.versionId}/rule/priority-selectable`
                              )
                            );
                          }}
                          className="primaryButton"
                          disabled={viewStatus === "true" ? true : false}
                        />
                        {/* <PrimaryButton
                          title={"Rule Builder"}
                          onClick={() => {
                            navigate(
                              getPathPrefix(
                                `selected-datastream/rule-engine/versions/${params?.versionId}/rule/builder`
                              )
                            );
                          }}
                          className="primaryButton"
                        /> */}
                        <PrimaryButton
                          title={t("versionOverview.addRule")}
                          icon="fa-solid fa-plus"
                          onClick={() => {
                            navigate(
                              getPathPrefix(
                                `selected-datastream/rule-engine/versions/${params?.versionId}/rule/add?t=${CRUD_RULE_ENGINE_TYPES.CREATE}`
                              )
                            );
                          }}
                          className="primaryButton"
                          disabled={viewStatus === "true" ? true : false}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- content-wrapper ends --> */}
            {/* <!-- partial:partials/_footer.html --> */}
            <footer className="footer">
              <div className="d-sm-flex justify-content-center">
                <p className="mb-0">
                  Copyright © 2023{" "}
                  <a
                    rel="noreferrer"
                    style={{ textDecorationLine: "underline !important" }}
                    href="https://scandiautomation.com/"
                    target="_blank"
                  >
                    Scandi Automation
                  </a>
                  .
                </p>
              </div>
            </footer>
            {/* <!-- partial --> */}
          </div>
          {/* <!-- main-panel ends --> */}
        </div>
        {/* <!-- page-body-wrapper ends --> */}
      </div>
    </>
  );
};

export default RuleEngVersionOverview;
