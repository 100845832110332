import { useEffect, useMemo } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, NavLink, useLocation } from "react-router-dom";
import StorageManager from "../features/library/utils/storageManager";
import { USER_FORM_OPTION } from "../features/profile/UpdateUser";
import {
  getPathPrefix,
  joinClassNames,
  nameCapitalize,
} from "../utils/coreUtils";
function SideBar() {
  const location = useLocation();

  const [t] = useTranslation();

  const selectedDataStream = StorageManager.getSelectedDataStream();

  const companyUserDetailsData = useSelector(
    (state) => state.companyUserReducer.companyUserDetailsData
  );

  const streamObj = selectedDataStream
    ? JSON.parse(selectedDataStream)
    : undefined;

  const companyName = localStorage.getItem("companyName");

  const userListMenu = useMemo(() => {
    return [
      {
        title: t("sideBar.companyUser.main"),
        activeKey: "/company-user",
        childKey: "company-user",
        icon: "fa-solid fa-users",
        subMenu: [
          ...(companyUserDetailsData?.userRoleType === "CA"
            ? [
                {
                  title: t("sideBar.companyUser.userList"),
                  activeKey: "/company-user/user-list",
                  image: "/assets/images/user.svg",
                  redirect: getPathPrefix("company-user/user-list"),
                },
              ]
            : []),
          {
            title: t("sideBar.companyUser.routingUserList"),
            activeKey: "/company-user/routing-user-list",
            image: "/assets/images/user.svg",
            redirect: getPathPrefix("company-user/routing-user-list"),
          },
          {
            title: t("sideBar.companyUser.createUser"),
            activeKey: "/company-user/create-user",
            icon: "fa-solid fa-user-group",
            redirect: getPathPrefix(
              `company-user/create-user?t=${USER_FORM_OPTION.CREATE_USER}${
                companyUserDetailsData?.userRoleType === "CU" ? "&r=ru" : ""
              }`
            ),
          },
        ],
      },
    ];
  }, [companyUserDetailsData?.userRoleType, t]);

  const sideMenu = useMemo(() => {
    const isAdmin = companyUserDetailsData?.userRoleType === "CA";
    return [
      {
        title: t("sideBar.dashboard"),
        activeKey: "/dashboard",
        icon: "fa-solid fa-house",
        redirect: getPathPrefix("dashboard"),
      },
      streamObj
        ? {
            title: nameCapitalize(streamObj?.customDataStreamName),
            alt: nameCapitalize(streamObj?.customDataStreamName),
            activeKey: "/selected-datastream",
            icon: "fa-solid fa-calendar-day",
            childKey: "selected-datastream",
            subMenu: [
              {
                title: t("sideBar.estimate.ruleEngine"),
                image: "/assets/images/engine.svg",
                // redirect: getPathPrefix("selected-datastream/rule-engine"),
                redirect: getPathPrefix(
                  "selected-datastream/rule-engine/versions"
                ),
              },
              /* {
              title: t("sideBar.estimate.routing"),
              image: "/assets/images/routing.svg",
              redirect: getPathPrefix("selected-datastream/routing"),
            }, */
              {
                title: t("sideBar.estimate.geoGraphicalControl"),
                image: "/assets/images/globe.svg",
                redirect: getPathPrefix(
                  "selected-datastream/geographical-control"
                ),
              },
              {
                title: t("sideBar.dataStream.report"),
                activeKey: "/selected-datastream/report",
                image: "/assets/images/report-icon.png",
                redirect: getPathPrefix(
                  `selected-datastream/report/${streamObj?.dataStreamConfigId}`
                ),
              },
              ...(isAdmin
                ? [
                    {
                      title: t("sideBar.estimate.fieldConfiguration"),
                      image: "/assets/images/document.svg",
                      redirect: getPathPrefix(
                        `selected-datastream/config-json/${streamObj?.dataStreamConfigId}`
                      ),
                    },
                  ]
                : []),
            ],
          }
        : undefined,
      /* {
        title: t("sideBar.validation"),
        activeKey: "/validation",
        icon: "fa-solid fa-clipboard-check",
        redirect: getPathPrefix("validation"),
      },
      {
        title: t("sideBar.inspectReport"),
        activeKey: "/inspect-report",
        icon: "fa-solid fa-file-lines",
        redirect: getPathPrefix("inspect-report"),
      },
      {
        title: t("sideBar.eInvoice"),
        activeKey: "/e-invoice",
        icon: "fa-solid fa-file-lines",
        redirect: getPathPrefix("e-invoice"),
      }, */

      /* {
        title: t("sideBar.company.main"),
        activeKey: "/view-company",
        childKey: "view-company",
        icon: "fa-solid fa-building",
        subMenu: [
          {
            title: t("sideBar.company.viewCompany"),
            activeKey: "/view-company/view",
            image: "/assets/images/user.svg",
            redirect: getPathPrefix("view-company/view"),
          },
          {
            title: t("sideBar.company.manageSubscription"),
            activeKey: "/view-company/subscription",
            icon: "fa-solid fa-money-bill",
            redirect: getPathPrefix(`view-company/subscription`),
          },
        ],
      }, */
      {
        title: t("sideBar.dataStream.main"),
        activeKey: "/datastream",
        childKey: "datastream",
        icon: "fa-solid fa-network-wired",
        subMenu: [
          // {
          //   title: t("sideBar.dataStream.dataStreamGroup"),
          //   activeKey: "/datastream/group",
          //   icon: "fa-solid fa-arrow-right",
          //   redirect: getPathPrefix("datastream/group"),
          // },
          // {
          //   title: t("sideBar.dataStream.dataStreamSchema"),
          //   activeKey: "/datastream/schema",
          //   icon: "fa-solid fa-arrow-right",
          //   redirect: getPathPrefix("datastream/schema"),
          // },
          // {
          //   title: t("sideBar.dataStream.fieldConfiguration"),
          //   activeKey: "/datastream/field-config",
          //   icon: "fa-solid fa-arrow-right",
          //   redirect: getPathPrefix("datastream/field-config"),
          // },
          {
            title: t("sideBar.dataStream.processing"),
            activeKey: "/datastream/processing",
            image: "/assets/images/wheel.svg",
            redirect: getPathPrefix("datastream/processing"),
          },
          // {
          //   title: t("sideBar.dataStream.report"),
          //   activeKey: "/datastream/report",
          //   icon: "fa-solid fa-arrow-right",
          //   redirect: getPathPrefix("datastream/report"),
          // },
        ],
      },
      ...userListMenu,
      {
        title: t("sideBar.helpAndSupport.main"),
        activeKey: "/help-me",
        childKey: "help-me",
        icon: "fa-solid fa-circle-info",
        subMenu: [
          {
            title: t("sideBar.helpAndSupport.knowledgeBase"),
            activeKey: "/help-me/knowledge",
            icon: "fa-solid fa-lightbulb",
            redirect: getPathPrefix("help-me/knowledge"),
          },
          {
            title: t("sideBar.helpAndSupport.contactAicc"),
            activeKey: "/help-me/contact",
            icon: "fa-solid fa-square-phone",
            redirect: getPathPrefix("help-me/contact"),
          },
        ],
      },
    ].filter((e) => e);
  }, [streamObj, t, userListMenu]);

  return (
    <nav
      className="sidebar sidebar-offcanvas"
      id="sidebar"
      data-simplebar="init"
    >
      <div
        className="simplebar-wrapper"
        style={{
          margin: "0px",
        }}
      >
        <div className="simplebar-height-auto-observer-wrapper">
          <div className="simplebar-height-auto-observer"></div>
        </div>
        <div className="simplebar-mask">
          <div
            className="simplebar-offset"
            style={{
              right: "0px",
              bottom: "0px",
            }}
          >
            <div
              className="simplebar-content-wrapper"
              // style="height: 100%; overflow: scroll;"
              style={{
                height: "100%",
                overflow: "scroll",
              }}
            >
              <div
                className="simplebar-content"
                style={{
                  padding: "0px",
                }}
              >
                {companyName && (
                  <div
                    className="border-bottom border-top company-name-title mb-4 mt-2 px-3 py-3 text-center"
                    style={{
                      color: "white",
                    }}
                  >
                    {nameCapitalize(companyName)}
                  </div>
                )}
                <ul className="nav">
                  {sideMenu.map((e, parentIndex) => {
                    const haveChild = Boolean(e.subMenu);

                    const isActiveParent = location.pathname.includes(
                      e.activeKey
                    );

                    // single child
                    if (!haveChild) {
                      return (
                        <li
                          key={`parent-${parentIndex}`}
                          className={joinClassNames([
                            "nav-item",
                            isActiveParent ? "active" : "",
                          ])}
                        >
                          <a className={"nav-link"} href={e.redirect}>
                            {e.icon && (
                              <i className={`${e.icon} menu-icon`}></i>
                            )}
                            <span className="menu-title text-ellipsis">
                              {e.title}
                            </span>
                          </a>
                        </li>
                      );
                    }

                    // sub list
                    return (
                      <li
                        key={`parent-${parentIndex}`}
                        className={joinClassNames([
                          "nav-item",
                          "nav-parent",
                          isActiveParent ? "active" : "",
                        ])}
                      >
                        <a
                          className={"nav-link"}
                          data-toggle="collapse"
                          href={`#${e.childKey}`}
                          aria-expanded={isActiveParent ? "true" : "false"}
                          aria-controls={`${e.childKey}`}
                        >
                          {e.image && <img src={e.image} alt="" />}
                          {e.icon && (
                            <i
                              className={joinClassNames(["menu-icon", e.icon])}
                            ></i>
                          )}
                          {e.alt ? (
                            <OverlayTrigger
                              placement="auto"
                              // delay={{ show: 250, hide: 400 }}
                              overlay={(props) => (
                                <Tooltip id="button-tooltip" {...props}>
                                  {e.alt}
                                </Tooltip>
                              )}
                            >
                              <span className="menu-title text-ellipsis w-100">
                                {e.title}
                              </span>
                            </OverlayTrigger>
                          ) : (
                            <span className="menu-title text-ellipsis w-100">
                              {e.title}
                            </span>
                          )}

                          <i className="menu-arrow"></i>
                        </a>
                        <div
                          className={[
                            "collapse",
                            isActiveParent ? "show" : "",
                          ].join(" ")}
                          id={`${e.childKey}`}
                        >
                          <ul className="nav flex-column sub-menu">
                            {e.subMenu &&
                              e.subMenu.length > 0 &&
                              e.subMenu.map((sub, childIndex) => {
                                const isChildActive = sub.redirect.includes(
                                  location.pathname
                                );
                                return (
                                  <li
                                    className="nav-item"
                                    key={`parent-${parentIndex}-child-${childIndex}`}
                                  >
                                    <Link
                                      className={joinClassNames([
                                        "nav-link",
                                        "text-ellipsis",
                                        isChildActive ? "active" : "",
                                      ])}
                                      alt={"Hello"}
                                      to={sub.redirect}
                                    >
                                      {sub.image && (
                                        <img src={sub.image} alt="" />
                                      )}
                                      {sub.icon && <i className={sub.icon}></i>}
                                      {sub.title}
                                    </Link>
                                  </li>
                                );
                              })}
                          </ul>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div
          className="simplebar-placeholder"
          // style="width: auto; height: 794px;"
        ></div>
      </div>
      <div
        className="simplebar-track simplebar-horizontal"
        // style="visibility: visible;"
        style={{
          visibility: "visible",
        }}
      >
        <div
          className="simplebar-scrollbar"
          // style="width: 234px; display: block; transform: translate3d(0px, 0px, 0px);"
          style={{
            width: "234px",
            display: "block",
            transform: "translate3d(0px, 0px, 0px)",
          }}
        ></div>
      </div>
      <div
        className="simplebar-track simplebar-vertical"
        // style="visibility: visible;"
        style={{
          visibility: "visible",
        }}
      >
        <div
          className="simplebar-scrollbar"
          // style="height: 277px; transform: translate3d(0px, 0px, 0px); display: block;"
          style={{
            width: "277px",
            display: "block",
            transform: "translate3d(0px, 0px, 0px)",
          }}
        ></div>
      </div>
    </nav>
  );
}

export default SideBar;
