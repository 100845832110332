import React from "react";

function Routing() {
  return (
    <>
      <div class="container-scroller">
        {/* <!-- partial --> */}
        <div class="container-fluid page-body-wrapper p-0">
          {/* <!-- partial --> */}
          <div class="main-panel">
            <div class="content-wrapper">
              {/* <!-- breadcrumb-start --> */}
              <div class="page-breadcrumb d-none d-md-flex align-items-center mb-5">
                <div class="breadcrumb-title pe-3">Estimate</div>
                <div class="ps-3">
                  <nav aria-label="breadcrumb">
                    <ol class="breadcrumb mb-0 p-0">
                      <li class="breadcrumb-item">
                        <a href="#">
                          <i class="fa-solid fa-house"></i>
                        </a>
                      </li>
                      <li class="breadcrumb-item active" aria-current="page">
                        Routing
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
              {/* <!-- breadcrumb-end --> */}
              <div class="col-12">
                <div class="card main-card-table">
                  <div class="card-header">
                    <div class="row align-items-center">
                      <div class="col-12 col-md-4">
                        <div class="card-label">
                          <img
                            src="/assets/images/routing.svg"
                            alt=""
                            class="me-2"
                          />{" "}
                          Routing
                        </div>
                      </div>
                      <div class="col-12 col-md-8">
                        <div class="d-block d-md-flex justify-content-end">
                          <a href="#" type="button" class="btn">
                            <i class="fa-solid fa-plus me-2"></i>Add Routing
                          </a>
                          <a href="#" type="button" class="btn ms-md-3">
                            <i class="fa-regular fa-square-plus me-2"></i>Create
                            Active Version
                          </a>
                          <a href="#" type="button" class="btn ms-md-3">
                            <i class="fa-sharp fa-solid fa-rotate me-2"></i>
                            Change Name
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-body table-responsive">
                    <table class="table table-modern">
                      <thead>
                        <tr>
                          <td width="20px"></td>
                          <th>Version</th>
                          <th>Created Date/Time</th>
                          <th>Changed Date/Time</th>
                          <th>Edit By</th>
                          <th>Active</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div class="form-check form-check-sm form-check-custom">
                              <input class="form-check-input" type="checkbox" />
                            </div>
                          </td>
                          <td>
                            <a
                              href="routing-estimate.html"
                              class="text-primary text-decoration-underline"
                            >
                              Routing 5
                            </a>
                          </td>
                          <td>2022-04-18 11:25</td>
                          <td>2022-04-30 09:20</td>
                          <td>KS1980</td>
                          <td>Yes</td>
                          <td>
                            <span>
                              <a
                                href="#"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Copy"
                              >
                                <i class="fa-regular fa-copy"></i>
                              </a>
                            </span>
                            <span>
                              <a
                                href="#"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="View"
                              >
                                <i class="fa-regular fa-eye"></i>
                              </a>
                            </span>
                            <span>
                              <a
                                href="#"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Delete"
                              >
                                <i class="fa-regular fa-trash-can"></i>
                              </a>
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div class="form-check form-check-sm form-check-custom">
                              <input class="form-check-input" type="checkbox" />
                            </div>
                          </td>
                          <td>
                            <a
                              href="routing-estimate.html"
                              class="text-primary text-decoration-underline"
                            >
                              Routing 3
                            </a>
                          </td>
                          <td>2022-04-18 11:25</td>
                          <td>2022-04-30 09:20</td>
                          <td>KS1980</td>
                          <td>No</td>
                          <td>
                            <span>
                              <a
                                href="#"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Copy"
                              >
                                <i class="fa-regular fa-copy"></i>
                              </a>
                            </span>
                            <span>
                              <a
                                href="#"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="View"
                              >
                                <i class="fa-regular fa-eye"></i>
                              </a>
                            </span>
                            <span>
                              <a
                                href="#"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Delete"
                              >
                                <i class="fa-regular fa-trash-can"></i>
                              </a>
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div class="form-check form-check-sm form-check-custom">
                              <input class="form-check-input" type="checkbox" />
                            </div>
                          </td>
                          <td>
                            <a
                              href="routing-estimate.html"
                              class="text-primary text-decoration-underline"
                            >
                              Routing 1
                            </a>
                          </td>
                          <td>2022-04-18 11:25</td>
                          <td>2022-04-30 09:20</td>
                          <td>KS1980</td>
                          <td>Yes</td>
                          <td>
                            <span>
                              <a
                                href="#"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Copy"
                              >
                                <i class="fa-regular fa-copy"></i>
                              </a>
                            </span>
                            <span>
                              <a
                                href="#"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="View"
                              >
                                <i class="fa-regular fa-eye"></i>
                              </a>
                            </span>
                            <span>
                              <a
                                href="#"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Delete"
                              >
                                <i class="fa-regular fa-trash-can"></i>
                              </a>
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div class="form-check form-check-sm form-check-custom">
                              <input class="form-check-input" type="checkbox" />
                            </div>
                          </td>
                          <td>
                            <a
                              href="routing-estimate.html"
                              class="text-primary text-decoration-underline"
                            >
                              Routing 2
                            </a>
                          </td>
                          <td>2022-04-18 11:25</td>
                          <td>2022-04-30 09:20</td>
                          <td>KS1980</td>
                          <td>No</td>
                          <td>
                            <span>
                              <a
                                href="#"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Copy"
                              >
                                <i class="fa-regular fa-copy"></i>
                              </a>
                            </span>
                            <span>
                              <a
                                href="#"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="View"
                              >
                                <i class="fa-regular fa-eye"></i>
                              </a>
                            </span>
                            <span>
                              <a
                                href="#"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Delete"
                              >
                                <i class="fa-regular fa-trash-can"></i>
                              </a>
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- content-wrapper ends --> */}
            {/* <!-- partial:partials/_footer.html --> */}
            <footer class="footer">
              <div class="d-sm-flex justify-content-center">
                <p class="mb-0">
                  Copyright © 2023{" "}
                  <a
                    rel="noreferrer"
                    style={{ textDecorationLine: "underline !important" }}
                    href="https://scandiautomation.com/"
                    target="_blank"
                  >
                    Scandi Automation
                  </a>
                  .
                </p>
              </div>
            </footer>
            {/* <!-- partial --> */}
          </div>
          {/* <!-- main-panel ends --> */}
        </div>
        {/* <!-- page-body-wrapper ends --> */}
      </div>
    </>
  );
}

export default Routing;
