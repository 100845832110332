import React from "react";

function AddUser() {
  return (
    <div className="container-scroller">
      {/* partial */}
      <div className="container-fluid page-body-wrapper p-0">
        {/* partial */}
        <div className="main-panel">
          <div className="content-wrapper">
            {/* breadcrumb-start */}
            <div className="page-breadcrumb d-none d-md-flex align-items-center mb-5">
              <div className="breadcrumb-title pe-3">Company User</div>
              <div className="ps-3">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb mb-0 p-0">
                    <li className="breadcrumb-item">
                      <a href="#">
                        <i className="fa-solid fa-house" />
                      </a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      User List
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Add User
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
            {/* breadcrumb-end */}
            <div className="col-12 col-lg-8 col-md-12 mx-auto">
              <div className="card main-card-table">
                <div className="card-header">
                  <div className="row align-items-center">
                    <div className="col-12">
                      <div className="card-label mb-0">Add User</div>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="form">
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <label htmlFor className="form-label">
                            User Id
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="User Id"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <label htmlFor className="form-label">
                            First Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="First Name"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <label htmlFor className="form-label">
                            Last Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Last Name"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <label htmlFor className="form-label">
                            Role
                          </label>
                          <select className="form-select">
                            <option selected>Select</option>
                            <option value={1}>One</option>
                            <option value={2}>Two</option>
                            <option value={3}>Three</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <label htmlFor className="form-label">
                            Email Address
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Email Address"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <label htmlFor className="form-label">
                            Active
                          </label>
                          <br />
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="inlineRadioOptions1"
                              id="inlineRadio1"
                              defaultValue="option1"
                              defaultChecked
                            />
                            <label
                              className="form-check-label"
                              htmlFor="inlineRadio1"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="inlineRadioOptions1"
                              id="inlineRadio2"
                              defaultValue="option2"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="inlineRadio2"
                            >
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <h6 className="text-primary">Report Access</h6>
                    <hr />
                    <div className="row">
                      <div className="col-lg-6 col-md-4">
                        <div className="form-check mb-3">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            defaultValue
                            id="validation"
                            defaultChecked
                          />
                          <label
                            className="form-check-label ms-2"
                            htmlFor="validation"
                          >
                            Validation
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-4">
                        <div className="form-check mb-3">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            defaultValue
                            id="report"
                          />
                          <label
                            className="form-check-label ms-2"
                            htmlFor="report"
                          >
                            Inspection Report
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-4">
                        <div className="form-check mb-3">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            defaultValue
                            id="invoice"
                            defaultChecked
                          />
                          <label
                            className="form-check-label ms-2"
                            htmlFor="invoice"
                          >
                            Invoice
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="text-end mt-4">
                      <a href="#" type="button" className="btn">
                        <i className="fa-regular fa-floppy-disk me-2" />
                        Save
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* content-wrapper ends */}
          {/* partial:partials/_footer.html */}
          <footer className="footer">
            <div className="d-sm-flex justify-content-center">
              <p className="mb-0">
                Copyright © 2023{" "}
                <a
                  rel="noreferrer"
                  style={{ textDecorationLine: "underline !important" }}
                  href="https://scandiautomation.com/"
                  target="_blank"
                >
                  Scandi Automation
                </a>
                .
              </p>
            </div>
          </footer>
          {/* partial */}
        </div>
        {/* main-panel ends */}
      </div>
      {/* page-body-wrapper ends */}
    </div>
  );
}

export default AddUser;
