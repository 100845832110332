import { useState } from "react";
import { toast } from "react-toastify";
import PrimaryButton from "../../components/Form/Buttons/PrimaryButton";
import { useTranslation } from "react-i18next";
import openApi from "../../services/apis/openApi";
import { camelCase } from "lodash";
import {
  camelizeObject,
  readMyFile,
  replaceValuesWithBlank,
} from "../../utils/coreUtils";
function DataStreamProcess() {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedSchema, setSelectedSchema] = useState();
  const [subscriptionsKey, setSubScriptionsKey] = useState("");
  const [t, i18n] = useTranslation();
  const getAllRulesBySubKeys = async (file) => {
    // console.log("file", file);
    if (!file) {
      return;
    }

    try {
      setIsLoading(true);
      const formData = new FormData();
      // formData.append(
      //   `jsonFile`, new File([selectedSchema], `json_file.json`, {
      //     type: "application/json"
      //   })
      // );
      formData.append("jsonFile", file);
      formData.append("subscriptionKey", subscriptionsKey);
      const response = await openApi.getAllRulesBySubKey(formData);
      if (response.status === 200) {
        toast.success(t(`statusCodes.PROCESSING_SCHEMA_SUCCESS`));
      } else {
        toast.error(t(`statusCodes.PROCESSING_SCHEMA_ERROR}`));
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };
  const UploadSchema = () => {
    document.getElementById("fileInput").click();
  };

  const uploadSelectedSchema = (e) => {
    getAllRulesBySubKeys(selectedSchema);
    setSubScriptionsKey("");
    setSelectedSchema();
  };

  return (
    <div className="container-scroller">
      {/* <!-- partial --> */}
      <div className="container-fluid page-body-wrapper p-0">
        {/* <!-- partial --> */}
        <div className="main-panel">
          <div className="content-wrapper">
            {/* <!-- breadcrumb-start --> */}
            <div className="page-breadcrumb d-none d-md-flex align-items-center mb-5">
              <div className="breadcrumb-title pe-3">
                {" "}
                {t("processingScreen.dataStreamProcessing")}
              </div>
              <div className="ps-3">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb mb-0 p-0">
                    <li className="breadcrumb-item">
                      <a href="#">
                        <i className="fa-solid fa-house"></i>
                      </a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      {t("processingScreen.dataStreamProcessing")}
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
            {/* <!-- breadcrumb-end --> */}
            <div className="col-12">
              <div className="card main-card-table">
                <div className="card-header">
                  <div className="row">
                    <div className="col-12 col-md-5">
                      <div className="card-label">
                        <img
                          src="/assets/images/wheel.svg"
                          alt=""
                          className="me-2"
                          style={{ width: "29px", height: "27px" }}
                        />{" "}
                        {t("processingScreen.dataStreamProcessing")}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-6 col-md-6 justify-content-end">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control "
                          placeholder={t(
                            "processingScreen.subscriptionKeyPlaceholder"
                          )}
                          value={subscriptionsKey}
                          onChange={(e) => {
                            setSubScriptionsKey(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-6 col-md-6">
                      <div className="d-block d-md-flex justify-content-end">
                        <PrimaryButton
                          type="button"
                          title={t("processingScreen.upload")}
                          icon="fa-solid fa-upload"
                          className="ms-3"
                          onClick={UploadSchema}
                          disabled={!subscriptionsKey}
                        />
                        <input
                          id="fileInput"
                          type="file"
                          accept=".json"
                          style={{ display: "none" }}
                          value=""
                          onChange={(e) => {
                            e.preventDefault();
                            // read local file and set json content to form
                            // readMyFile(e.target.files[0])
                            //   .then((res) => {
                            //     const dataFile = JSON.parse(res)
                            //     setSelectedSchema(
                            //       JSON.stringify(
                            //         camelizeObject(
                            //           replaceValuesWithBlank(
                            //             dataFile
                            //           )
                            //         ),
                            //         null,
                            //         2
                            //       )
                            //     );
                            //   }).catch((error) => {
                            //     toast.error(
                            //       "Invalid JSON File"
                            //     );
                            //   });
                            setSelectedSchema(e.target.files[0]);
                          }}
                        />
                        {/* {console.log(selectedSchema, subscriptionsKey)} */}
                        <div className="mx-2">
                          <PrimaryButton
                            type="button"
                            title={
                              isLoading
                                ? t("processingScreen.processing")
                                : t("processingScreen.process")
                            }
                            disabled={!selectedSchema || !subscriptionsKey}
                            onClick={uploadSelectedSchema}
                            style={{ marginRight: "-21px" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- content-wrapper ends --> */}
          {/* <!-- partial:partials/_footer.html --> */}
          <footer className="footer">
            <div className="d-sm-flex justify-content-center">
              <p className="mb-0">
                Copyright © 2023{" "}
                <a
                  rel="noreferrer"
                  style={{ textDecorationLine: "underline !important" }}
                  href="https://scandiautomation.com/"
                  target="_blank"
                >
                  Scandi Automation
                </a>
                .
              </p>
            </div>
          </footer>
          {/* <!-- partial --> */}
        </div>
        {/* <!-- main-panel ends --> */}
      </div>
      {/* <!-- page-body-wrapper ends --> */}
    </div>
  );
}

export default DataStreamProcess;
