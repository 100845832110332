import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { NavLink, useNavigate } from "react-router-dom";
import { isEmpty } from "../../library/utils/common";
import Input from "../../../library/components/input.component";
import LeftPanel from "../components/leftPanel.component";

import { changeForgetPasswordAction } from "../redux/actions/auth.action";
import { toast } from "react-toastify";
import { colors } from "../../../library/theme/palette";
import { useTranslation } from "react-i18next";
import { getPathPrefix } from "../../../utils/coreUtils";
import moment from "moment";
import StorageManager from "../../library/utils/storageManager";
import ToastUtils from "../../../utils/ToastUtils";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isLoading = useSelector((state) => state.authReducer.isLoading);
  const [t, i18n] = useTranslation();

  const [forgotPasswordState, setForgotPasswordState] = useState({
    emailId: "",
    userCode: "",
  });

  const handleChangeEmail = async (e, type) => {
    forgotPasswordState[type] = e.target.value;

    setForgotPasswordState({
      ...forgotPasswordState,
    });
  };

  const handleForgetPassword = async (e) => {
    e.preventDefault();
    if (!isEmpty(forgotPasswordState.emailId)) {
      await dispatch(
        changeForgetPasswordAction(forgotPasswordState.emailId, "CA106")
      )
        .then((res) => {
          StorageManager.set("otpSentTime", moment().toISOString());
          navigate(getPathPrefix("otp"), {
            state: {
              forgotPasswordState: forgotPasswordState.emailId,
            },
          });
          if (res.status != 200) {
            ToastUtils.serverError(t(`statusCodes.${res?.code}`));
          } else {
            toast.success(t(`statusCodes.${res?.code}`));
          }
        })
        .catch((e) => {
          if (typeof e.data === "string") {
            toast.error(e.data);
          } else {
            toast.error(e.data?.message);
          }
        });
    } else {
      // alert("Forgot Password Failed");
      // toast.error("Please enter email address");
      toast.error(t("forgotPassword.errorMessage"));
    }
  };

  return (
    <section className="page main-signin-wrapper container-layout align-items-center">
      <div className="row signpages bg-app-primary">
        <div className="col-md-12">
          <div className="row">
            <LeftPanel />
            <div className="col-lg-6 right-panel">
              <div className="form-container d-flex flex-column">
                <img
                  src={"/assets/images/scandi-name-automation.png"}
                  className="header-brand-img text-start mb-4 mx-auto"
                  alt="logo"
                />
                <form className="d-flex flex-column flex-fill justify-content-center">
                  {/* <RightPanelHeader title={t("language.forgot_password")} /> */}
                  <h4
                    className="text-primary-app mb-4 text-center"
                    style={{ fontWeight: "600" }}
                  >
                    {/* Forgot Password? */}
                    {t("forgotPassword.title")}
                  </h4>
                  <div className="form-group mb-space w-100">
                    <Input
                      onChange={(e) => handleChangeEmail(e, "emailId")}
                      value={forgotPasswordState.emailId}
                      // placeholder={placeholders.userName_Email}
                      placeholder={t("forgotPassword.emailPlaceholder")}
                      type={"text"}
                    />
                  </div>
                  <div className="form-group text-center">
                    <button
                      className="btn btn-fill text-uppercase"
                      onClick={handleForgetPassword}
                      disabled={isEmpty(forgotPasswordState.emailId)}
                      style={
                        isEmpty(forgotPasswordState.emailId)
                          ? { opacity: 0.4, cursor: "not-allowed" }
                          : {}
                      }
                    >
                      {isLoading && (
                        <img
                          src={
                            "https://www.ssdream.co.kr/front/event/img/loading.gif"
                          }
                          color={colors.white}
                          height={"25"}
                          width={"25"}
                        />
                      )}
                      {/* Submit */}
                      {t("CommonKeys.submitButtonTitle")}
                    </button>
                  </div>
                  <div className="form-group text-center">
                    <NavLink to={getPathPrefix("login")} className="">
                      {t("CommonKeys.backTo")}{" "}
                      <b>{t("CommonKeys.login_hyphen")}</b>
                    </NavLink>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*<ToastComponent /> */}
    </section>
  );
};

export default ForgotPassword;
