import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import AiccBreadCrumb from "../../../components/common/AiccBreadCrumb";
import SideBar from "../../../components/SideBar";
import {
  CRUD_GEOGRAPHICAL_TYPES,
  getPathPrefix,
} from "../../../utils/coreUtils";
import StorageManager from "../../library/utils/storageManager";
import BasicTextInput from "../../../components/Form/Inputs/common/BasicTextInput";
import PrimaryButton from "../../../components/Form/Buttons/PrimaryButton";
import { useRef } from "react";
import geographicalAPi from "../../../services/apis/geographicalAPi";
import ToastUtils from "../../../utils/ToastUtils";
import { toast } from "react-toastify";
import { useMemo } from "react";
import { Button, Transfer } from "antd";
import "../styles.scss";
import { read, utils, writeFile } from "xlsx";
import CustomModal from "../../../components/Modals/CustomModal";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
// const mockData = Array.from({
//   length: 20,
// }).map((_, i) => ({
//   key: i.toString(),
//   title: `content${i + 1}`,
//   description: `description of content${i + 1}`,
// }));
// const initialTargetKeys = mockData
//   .filter((item) => Number(item.key) > 10)
//   .map((item) => item.key);

const AddGeographicalArea = () => {
  const navigate = useNavigate();
  const [t, i18n] = useTranslation();
  const selectedDataStream = StorageManager.getSelectedDataStream();
  const streamObj = selectedDataStream
    ? JSON.parse(selectedDataStream)
    : undefined;

  const [mockData, setMockData] = useState([]);
  const [uploadedFileName, setUploadedFileName] = useState();
  const [geographicalState, setGeographicalState] = useState({
    areaName: "",
    isActive: false,
    other: "",
  });
  const [errors, setErrors] = useState({});
  const [error, setError] = useState("");
  const [sheetObj, setSheetObj] = useState();
  const [selectedSheet, setSelectedSheet] = useState(0);
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const [targetKeys, setTargetKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [zipCode, setZipCode] = useState("");
  const [showAddZipCode, setShowAddZipCode] = useState(false);
  const [showMoreSheet, setShowMoreSheet] = useState(false);

  const inputRef = useRef();
  const [searchParams] = useSearchParams();
  const type = searchParams.get("t");
  const params = useParams();

  const breadCrumbTitle = useMemo(() => {
    if (type === CRUD_GEOGRAPHICAL_TYPES.CREATE) {
      return t("versionsForm.createTitle");
    }
    if (type === CRUD_GEOGRAPHICAL_TYPES.EDIT) {
      return t("versionsForm.updateTitle");
    }
    if (type === CRUD_GEOGRAPHICAL_TYPES.VIEW) {
      return t("versionsForm.viewTitle");
    }

    return t("versionsForm.createTitle");
  }, [searchParams]);

  useEffect(() => {
    if (
      type === CRUD_GEOGRAPHICAL_TYPES.EDIT ||
      type === CRUD_GEOGRAPHICAL_TYPES.VIEW
    ) {
      handleEdit();
    }
  }, [type]);

  const handleUpload = () => {
    inputRef.current?.click();
  };

  const loadZipCode = (sheetData, sheetIndex) => {
    const rows = utils.sheet_to_json(
      sheetData.Sheets[sheetData.SheetNames[sheetIndex]]
    );
    const allKeys = Object.keys(rows[0] ?? {});

    const finalKey = allKeys.find(
      (e) => e.toLowerCase() === "zip" || e.toLowerCase() === "zipcode"
    );

    if (!finalKey) {
      toast.error(t("geographical.form.noZipCodeFoundFromSheet"));
      return;
    }

    let takeMokeData = rows.map((item, i) => ({
      key: item[finalKey]?.toString(),
      title: item[finalKey],
      description: `description of zip${i + 1}`,
    }));
    setMockData(takeMokeData);
    setShowMoreSheet(false);
  };

  const handleDisplayFileDetails = (event) => {
    inputRef.current?.files &&
      setUploadedFileName(inputRef.current.files[0].name);

    try {
      const files = inputRef.current.files;
      if (files.length) {
        const file = files[0];
        const reader = new FileReader();
        reader.onload = (event) => {
          const wb = read(event.target.result);
          const sheets = wb.SheetNames;

          if (sheets.length === 1) {
            loadZipCode(wb, 0);
            // setTargetKeys(rows);
          } else {
            if (sheets.length > 1) {
              setSheetObj(wb);
              setSelectedSheet(0);
              setShowMoreSheet(true);
            }
          }
        };
        reader.readAsArrayBuffer(file);
      }
    } catch (error) {}

    inputRef.current.value = "";
  };

  const handleEdit = async () => {
    // setIsLoading(true);
    try {
      const response = await geographicalAPi.getGeographicalAreaControlById({
        geographicalAreaControlId: params.geographicId,
      });
      geographicalState.areaName = response?.data?.geographicalAreaName;
      geographicalState.isActive = response?.data?.isActive;

      let takeTargetKey = response?.data?.zipCodeList.map((item, i) =>
        item.toString()
      );
      setTargetKeys(takeTargetKey);

      setMockData(
        response?.data?.zipCodeList.map((item, i) => ({
          key: item?.toString(),
          title: item,
        }))
      );
      geographicalState.other = response?.data;
      setGeographicalState({ ...geographicalState });
    } catch (error) {
      console.error(error);
    }
    // setIsLoading(false);
  };

  // for transfer
  const onChange = (nextTargetKeys, direction, moveKeys) => {
    setTargetKeys(nextTargetKeys);
  };
  const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const onScroll = (direction, e) => {};

  //for error handling
  const buttonDisabled = useMemo(() => {
    if (
      Object.keys(errors).filter((e) => errors[e]).length > 0 ||
      !geographicalState.areaName
    ) {
      return true;
    } else {
      return false;
    }
  }, [errors, geographicalState]);

  const updateError = (key, value) => {
    setErrors((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  const updateErrorForInputs = (inputValue, inputKey, error) => {
    if (!inputValue) {
      updateError(inputKey, error);
      setSubmitDisabled(true);
    } else {
      updateError(inputKey, undefined);
      setSubmitDisabled(false);
    }
  };
  const handleChange = (event) => {
    const inputValue = event.target.value;
    if (inputValue.length > 6) {
      setError("You cannot add more than 6 digits.");
    } else {
      setZipCode(inputValue);
      setError("");
    }
  };

  const onSubmitHandler = async (isOverrite) => {
    const createRequest = {
      geographicalAreaControlId:
        type === CRUD_GEOGRAPHICAL_TYPES?.EDIT
          ? geographicalState?.other?.geographicalAreaControlId
          : 0,
      geographicalAreaName: geographicalState.areaName,
      isActive: geographicalState.isActive,
      isDeleted: false,
      dsConfigId: streamObj?.dataStreamConfigId,
      zipCodeList: targetKeys,
    };
    try {
      const response =
        await geographicalAPi.saveOrUpdateGeographicalAreaControl(
          createRequest
        );
      if (response.status === 200) {
        if (response.data.status != 200) {
          ToastUtils.serverError(t(`statusCodes.${response?.data?.code}`));
        } else {
          toast.success(t(`statusCodes.${response?.data?.code}`));
        }
        navigate(-1);
      } else {
        // toast.error(t("versionsForm.errorMessage.ruleSaveFailure"));
      }
    } catch (error) {
      // if (error.response.data.isDSConfigVersionPriorityAlready) {
      //   setPrioritySame(error.response.data.isDSConfigVersionPriorityAlready);
      // } else if (error.response.data.status != 200) {
      //   ToastUtils.serverError(t(`statusCodes.${error.response.data.code}`));
      // }
      toast.error(error.response?.data);
    }
  };

  const onSaveZipModal = () => {
    let takeMokeData = {
      key: zipCode?.toString(),
      title: zipCode,
    };
    setMockData((prev) => [...prev, takeMokeData]);
    setShowAddZipCode(false);
    setZipCode("");
  };

  return (
    <>
      <CustomModal
        show={showAddZipCode}
        centered
        noHeader
        body={
          <div className="col-lg-12 col-md-12">
            <BasicTextInput
              type="number"
              label={t("geographical.zipCode")}
              isRequired={true}
              groupProps={{
                controlId: "zipCode",
                // className: "mb-3",
              }}
              inputProps={{
                placeholder: t("geographical.zipCode"),
              }}
              name="zipCode"
              value={zipCode}
              onChange={
                // updateErrorForInputs(
                //   e.target.value,
                //   undefined,
                //   "zipCode",
                //   "Please enter a zipCode",
                //   e.target.min,
                //   e.target.max
                // );
                // setZipCode(e.target.value);
                handleChange
              }
              formLabelClass={{
                className: "mt-3",
              }}
              // onBlur={(e) => {
              //   updateErrorForInputs(
              //     e.target.value,
              //     undefined,
              //     "zipCode",
              //     "Please enter a zipCode",
              //     e.target.min,
              //     e.target.max
              //   );
              // }}
            />

            {error && (
              <p
                style={{ height: "20px", fontSize: "13px" }}
                className="text-danger mt-1"
              >
                {error}
              </p>
            )}
          </div>
        }
        okTitle={t("CommonKeys.saveButtonTitle")}
        cancelTitle={t("CommonKeys.cancelButtonTitle")}
        centerFooter
        submitDisabled={!zipCode}
        onOk={() => {
          onSaveZipModal();
        }}
        onCancel={() => {
          setShowAddZipCode(false);
        }}
      />

      <CustomModal
        show={showMoreSheet}
        centered
        // noHeader
        title={t("geographical.form.sheetListTitle")}
        body={
          <div className="col-lg-12 col-md-12">
            {(sheetObj?.SheetNames ?? []).map((e, index) => {
              return (
                <Form.Check
                  inline
                  type="radio"
                  name="sheetList"
                  id={`sheet-${index}`}
                  checked={index === selectedSheet}
                  label={e}
                  onChange={() => {
                    setSelectedSheet(index);
                  }}
                />
              );
            })}
          </div>
        }
        okTitle={t("CommonKeys.saveButtonTitle")}
        cancelTitle={t("CommonKeys.cancelButtonTitle")}
        centerFooter
        onOk={() => {
          loadZipCode(sheetObj, selectedSheet);
        }}
        onCancel={() => {
          setShowMoreSheet(false);
        }}
      />
      <div class="container-scroller main-geographical">
        <div class="main-panel">
          <div class="content-wrapper">
            {/* <!-- breadcrumb-start --> */}
            <AiccBreadCrumb
              homeLink={getPathPrefix("dashboard")}
              title={streamObj?.customDataStreamName}
              items={t("geographical.geographicalAddArea.breadCrumb.items", {
                returnObjects: true,
              })}
            />
            {/* <!-- breadcrumb-end --> */}
            {/* <div class="col-12 col-lg-8 col-md-12 mx-auto"> */}
            <div class="col-12">
              <div class="card main-card-table">
                <div class="card-header">
                  <div class="row align-items-center">
                    <div class="col-12 col-md-4">
                      <div class="card-label mb-0">
                        <img
                          src="/assets/images/globe.svg"
                          alt=""
                          className="me-2"
                        />{" "}
                        {/* {t("geographical.addArea")} - {breadCrumbTitle} */}
                        {breadCrumbTitle} - {streamObj?.customDataStreamName}
                      </div>
                    </div>
                    <div class="col-12 col-md-8">
                      <div class="d-block d-md-flex justify-content-end">
                        {/* <a href="#" type="button" class="btn">
                        <i class="fa-regular fa-file-excel me-2"></i>Upload From
                        Excel
                      </a> */}
                        <input
                          ref={inputRef}
                          onChange={handleDisplayFileDetails}
                          className="d-none"
                          type="file"
                          accept=".xlsx, .xls, .csv"
                        />
                        {/* <PrimaryButton
                        title={t("geographical.uploadFromExcel")}
                        icon="fa-regular fa-file-excel"
                        className="me-2"
                        onClick={handleUpload}
                        // onClick={() => {
                        //   navigate(
                        //     getPathPrefix() +
                        //       "selected-datastream/geographical-control/create/"
                        //   );
                        // }}
                      /> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-body add-geo-area_card-body">
                  <div class="form add-geo-area_form">
                    <div class="row">
                      <div className="col-lg-6 col-md-6">
                        <BasicTextInput
                          label={t("geographical.form.areaName.label")}
                          isRequired={true}
                          groupProps={{
                            controlId: "rule-name",
                          }}
                          inputProps={{
                            placeholder: t(
                              "geographical.form.areaName.placeholder"
                            ),
                          }}
                          name="areaName"
                          value={geographicalState?.areaName}
                          onChange={(e) => {
                            updateErrorForInputs(
                              e.target.value,
                              "areaName",
                              "Please enter a area name"
                            );
                            setGeographicalState((prev) => ({
                              ...prev,
                              areaName: e.target.value,
                            }));
                          }}
                          onBlur={(e) => {
                            updateErrorForInputs(
                              e.target.value,
                              "areaName",
                              "Please enter a area name"
                            );
                          }}
                          disabled={type === CRUD_GEOGRAPHICAL_TYPES.VIEW}
                        />
                        {errors.areaName && (
                          <span
                            style={{ height: "20px", fontSize: "13px" }}
                            className="text-danger mt-1"
                          >
                            {errors.areaName}
                          </span>
                        )}
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="switch">
                          <label className="form-label">
                            {t("geographical.form.status")}
                          </label>
                          <input
                            type="checkbox"
                            id="isActive"
                            name="isActive"
                            disabled={
                              type === CRUD_GEOGRAPHICAL_TYPES.VIEW
                                ? true
                                : false
                            }
                            checked={geographicalState.isActive}
                            onChange={(e) => {
                              setGeographicalState((prev) => ({
                                ...prev,
                                isActive: e.target.checked,
                              }));
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="main-transfer-list add-geo-area_main-transfer">
                    <div className="row col-6">
                      <div className="col text-center text-primary add-geo-area-txt-ctr">
                        <b>{t("geographical.form.sourceZipCodes")}</b>
                      </div>
                      <div className="col text-end text-primary add-geo-area-txt-end">
                        <b>{t("geographical.form.selectedZipCodes")}</b>
                      </div>
                    </div>
                    <Transfer
                      dataSource={mockData}
                      // titles={["Source", "Target"]}
                      titles={[
                        <div className="col-lg-8 col-md-8 d-flex mx-auto mt-3 mb-1 justify-content-end w-auto">
                          <OverlayTrigger
                            placement="left-end"
                            overlay={(props) => (
                              <Tooltip id="button-tooltip" {...props}>
                                {t("geographical.uploadFromExcel")}
                              </Tooltip>
                            )}
                          >
                            <span style={{ cursor: "pointer" }}>
                              <PrimaryButton
                                // title={t("geographical.uploadFromExcel")}
                                disabled={type === CRUD_GEOGRAPHICAL_TYPES.VIEW}
                                icon="fa-solid fa-file-arrow-up"
                                className="me-2 mb-2"
                                onClick={handleUpload}
                                style={{
                                  height: "32px",
                                  width: "20px !important",
                                }}
                                iconStyle={{
                                  width: "2px",
                                  fontSize: "17px",
                                  marginLeft: "-4px",
                                }}
                              />
                            </span>
                          </OverlayTrigger>
                          <OverlayTrigger
                            placement="auto"
                            overlay={(props) => (
                              <Tooltip id="button-tooltip" {...props}>
                                {t("geographical.addZipCode")}
                              </Tooltip>
                            )}
                          >
                            <span style={{ cursor: "pointer" }}>
                              <PrimaryButton
                                // title={t("geographical.addZipCode")}
                                disabled={type === CRUD_GEOGRAPHICAL_TYPES.VIEW}
                                icon="fa-solid fa-plus"
                                className="mb-2"
                                onClick={() => {
                                  setShowAddZipCode(true);
                                }}
                                style={{
                                  height: "32px",
                                  width: "20px !important",
                                }}
                                iconStyle={{
                                  width: "2px",
                                  fontSize: "17px",
                                  marginLeft: "-4px",
                                }}
                              />
                            </span>
                          </OverlayTrigger>
                        </div>,
                      ]}
                      targetKeys={targetKeys}
                      selectedKeys={selectedKeys}
                      onChange={onChange}
                      onSelectChange={onSelectChange}
                      onScroll={onScroll}
                      render={(item) => item.title}
                      // className="d-flex align-items-center justify-content-center"
                      disabled={type === CRUD_GEOGRAPHICAL_TYPES.VIEW}
                      // footer={renderFooter}
                    />
                  </div>
                  {/* <div class="form">
                  <div class="row">
                    <div class="col-lg-6 col-md-6">
                      <div class="form-group">
                        <label for="" class="form-label">
                          Area Name
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Area Name"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row align-items-center">
                    <div class="col-lg-5 col-md-5">
                      <div class="form-group">
                        <label for="" class="form-label">
                          Zip Code (Selected)
                        </label>
                        <textarea
                          name=""
                          rows="10"
                          class="form-control"
                        ></textarea>
                      </div>
                    </div>
                    <div class="col-lg-2 col-md-2">
                      <div class="area-operators">
                        <div>&lt;</div>
                        <div>{"<<"} </div>
                        <div>{">>"}</div>
                        <div>&gt;</div>
                      </div>
                    </div>
                    <div class="col-lg-5 col-md-5">
                      <div class="form-group">
                        <label for="" class="form-label">
                          Zip Code (Not Selected)
                        </label>
                        <textarea
                          name=""
                          rows="10"
                          class="form-control"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div class="text-end mt-4">
                    <a href="#" type="button" class="btn">
                      <i class="fa-regular fa-floppy-disk me-2"></i>Save
                    </a>
                  </div>
                </div> */}
                </div>
                <div className="text-end mt-3 mb-3">
                  <PrimaryButton
                    title={t("CommonKeys.backButtonTitle")}
                    icon="fa-solid fa-arrow-left"
                    onClick={() => {
                      navigate(-1);
                    }}
                  />
                  {!(type === CRUD_GEOGRAPHICAL_TYPES.VIEW) && (
                    <PrimaryButton
                      title={t("CommonKeys.saveButtonTitle")}
                      icon="fa-regular fa-floppy-disk"
                      className="mx-3"
                      onClick={() => {
                        onSubmitHandler();
                      }}
                      disabled={buttonDisabled}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- content-wrapper ends --> */}
        {/* <!-- partial:partials/_footer.html --> */}
        <footer class="footer">
          <div class="d-sm-flex justify-content-center">
            <p class="mb-0">
              Copyright © 2023{" "}
              <a
                rel="noreferrer"
                style={{ textDecorationLine: "underline !important" }}
                href="https://scandiautomation.com/"
                target="_blank"
              >
                Scandi Automation
              </a>
              .
            </p>
          </div>
        </footer>
        {/* <!-- partial --> */}
      </div>
    </>
  );
};
export default AddGeographicalArea;
