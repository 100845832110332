import React from "react";
import { Spinner } from "react-bootstrap";
import CustomModal from "../Modals/CustomModal";

const CenterLoading = ({ minHeight, asModal, isVisible }) => {
  const viewToRender = () => {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{
          minHeight: asModal ? undefined : minHeight ?? "500px",
        }}
      >
        {/* <Spinner animation="border" role="status" /> */}
        <div className="demo-container">
          <div className="text-center">
            <img
              src={"/assets/images/Scandi-automation-gif.png"}
              className=""
              alt={"logo-2"}
              width="60"
              height="60"
            />
          </div>
          <div className="progress-bar">
            <div className="progress-bar-value"></div>
          </div>
        </div>
      </div>
    );
  };

  if (!asModal) {
    return viewToRender();
  }

  return (
    <CustomModal
      size={"sm"}
      noHeader
      show={isVisible}
      centered
      body={viewToRender()}
    />
  );
};

export default CenterLoading;
