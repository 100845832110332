import React from "react";
import { NavLink } from "react-router-dom";

function FieldConfiguration() {
  return (
    <div className="container-scroller">
      {/* <!-- partial --> */}
      <div className="container-fluid page-body-wrapper p-0">
        {/* <!-- partial --> */}
        <div className="main-panel">
          <div className="content-wrapper">
            {/* <!-- breadcrumb-start --> */}
            <div className="page-breadcrumb d-none d-md-flex align-items-center mb-5">
              <div className="breadcrumb-title pe-3">Field Configuaration</div>
              <div className="ps-3">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb mb-0 p-0">
                    <li className="breadcrumb-item">
                      <a href="#">
                        <i className="fa-solid fa-house"></i>
                      </a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Field Configuaration
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
            {/* <!-- breadcrumb-end --> */}
            <div className="col-12">
              <div className="card main-card-table">
                <div className="card-header">
                  <div className="row align-items-center">
                    <div className="col-12 col-md-5">
                      <div className="card-label">
                        <img
                          src="/assets/images/wheel.svg"
                          alt=""
                          className="me-2"
                        />{" "}
                        Field Configuaration - Estimate
                      </div>
                    </div>
                    <div className="col-12 col-md-7">
                      <div className="d-block d-md-flex justify-content-end">
                        <NavLink to="/add-field" type="button" className="btn">
                          <i className="fa-solid fa-plus me-2"></i>Add Field
                        </NavLink>
                        <a href="#" type="button" className="btn ms-md-3">
                          <i className="fa-solid fa-plus me-2"></i>Add Category
                        </a>
                        <NavLink
                          to="/upload-schema"
                          type="button"
                          className="btn ms-md-3"
                        >
                          <i className="fa-solid fa-upload me-2"></i>Upload
                          Schema
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-xl-12">
                      <div
                        className="nav nav-pills nav-pills-custom justify-content-center"
                        id="v-pills-tab"
                        role="tablist"
                        aria-orientation="vertical"
                      >
                        <a
                          className="nav-link active"
                          id="work-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#work"
                          type="button"
                          role="tab"
                          aria-controls="work"
                          aria-selected="true"
                        >
                          <i className="fa-solid fa-circle-dot me-2"></i>Work
                        </a>
                        <a
                          className="nav-link"
                          id="photo-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#photo"
                          type="button"
                          role="tab"
                          aria-controls="photo"
                          aria-selected="false"
                        >
                          <i className="fa-solid fa-circle-dot me-2"></i>Photos
                        </a>
                        <a
                          className="nav-link"
                          id="claim-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#claim"
                          type="button"
                          role="tab"
                          aria-controls="claim"
                          aria-selected="false"
                        >
                          <i className="fa-solid fa-circle-dot me-2"></i>Claim
                          Data
                        </a>
                        <a
                          className="nav-link"
                          id="total-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#total"
                          type="button"
                          role="tab"
                          aria-controls="total"
                          aria-selected="false"
                        >
                          <i className="fa-solid fa-circle-dot me-2"></i>Total
                        </a>
                      </div>
                    </div>
                    <div className="col-xl-12">
                      <div className="tab-content" id="v-pills-tabContent">
                        <div
                          className="tab-pane fade show active"
                          id="work"
                          role="tabpanel"
                          aria-labelledby="work-tab"
                        >
                          <div className="table-responsive">
                            <table className="table table-modern">
                              <thead>
                                <tr>
                                  <th>Path</th>
                                  <th>Name</th>
                                  <th>Type</th>
                                  <th>Source</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    <a href="#">
                                      Estimate.CalculationResult.Totals.WorkResultSubTotals_1
                                    </a>
                                  </td>
                                  <td>Work Periods</td>
                                  <td>Numeric</td>
                                  <td>
                                    $.Estimate.CalculationResult.Totals.WorkResultSubTotals.WorkPeriods
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <a href="#">
                                      Estimate.CalculationResult.Totals.WorkResultSubTotals_2
                                    </a>
                                  </td>
                                  <td>Glass Period</td>
                                  <td>Numeric</td>
                                  <td>
                                    $.Estimate.CalculationResult.Totals.WorkResultSubTotals.GlassWorkPeriods
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <a href="#">
                                      Estimate.CalculationResult.Totals.WorkResultSubTotals_3
                                    </a>
                                  </td>
                                  <td>Mechanic Periods</td>
                                  <td>True</td>
                                  <td>
                                    $.Estimate.CalculationResult.Totals.WorkResultSubTotals.MechanicalWorkPeriods
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="photo"
                          role="tabpanel"
                          aria-labelledby="photo-tab"
                        >
                          2
                        </div>
                        <div
                          className="tab-pane fade"
                          id="claim"
                          role="tabpanel"
                          aria-labelledby="claim-tab"
                        >
                          3
                        </div>
                        <div
                          className="tab-pane fade"
                          id="total"
                          role="tabpanel"
                          aria-labelledby="total-tab"
                        >
                          4
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- content-wrapper ends --> */}
          {/* <!-- partial:partials/_footer.html --> */}
          <footer className="footer">
            <div className="d-sm-flex justify-content-center">
              <p className="mb-0">
                Copyright © 2023{" "}
                <a
                  rel="noreferrer"
                  style={{ textDecorationLine: "underline !important" }}
                  href="https://scandiautomation.com/"
                  target="_blank"
                >
                  Scandi Automation
                </a>
                .
              </p>
            </div>
          </footer>
          {/* <!-- partial --> */}
        </div>
        {/* <!-- main-panel ends --> */}
      </div>
      {/* <!-- page-body-wrapper ends --> */}
    </div>
  );
}

export default FieldConfiguration;
