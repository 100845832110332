import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  companyDeleteUserIdAction,
  companyUserDetailsAction,
  companyUserListAction,
} from "../redux/actions/company_user_action";
import { colors } from "../../../library/theme/palette";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import profilePic from "../../../../src/components/user-icon.png";
import { getPathPrefix, nameCapitalize } from "../../../utils/coreUtils";
import { USER_FORM_OPTION } from "../../profile/UpdateUser";
import { useMemo } from "react";
import { ConfirmationModal } from "../../../components/Modals/ConfirmationModal";
import { jsonToFormData } from "../../../utils/ServicesUtils";
import companyUserApi from "../../../services/apis/companyUserApi";
import AiccBreadCrumb from "../../../components/common/AiccBreadCrumb";
import ToastUtils from "../../../utils/ToastUtils";
import openApi from "../../../services/apis/openApi";
import "./style.scss";

const UserListPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const companyUserId = useMemo(
    () => localStorage.getItem("companyUserId"),
    []
  );
  const [search, setSearch] = useState("");
  const [configList, setConfigList] = useState([]);

  const companyUserListData = useSelector(
    (state) => state.companyUserReducer.companyUserListData
  );
  const isLoading = useSelector((state) => state.companyUserReducer.isLoading);
  const [t] = useTranslation();

  const getCompanyUserList = () => {
    dispatch(
      companyUserListAction({
        name: search,
      })
    );
  };

  const getDataStreamConfig = async () => {
    try {
      const { data } = await openApi.getDataStreamConfig();
      if (typeof data !== "string") {
        setConfigList(data ?? []);
      }
    } catch (error) {}
  };

  useEffect(() => {
    setSearch("");
    getCompanyUserList();
    getDataStreamConfig();
  }, [dispatch, searchParams.get("t")]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      getCompanyUserList();
    }, 500);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [search]);

  const checkHandler = async (companyUserId, status) => {
    try {
      const formData = jsonToFormData({
        companyUserId,
        isActive: status,
      });

      const response = await companyUserApi.updateCompanyProfile(formData);

      if (response.data.status != 200) {
        toast.error(t(`statusCodes.${response?.data?.code}`));
      } else {
        toast.success("Status updated");
      }

      getCompanyUserList();
    } catch (error) {}
  };

  const handleDeleteUser = async (index) => {
    await dispatch(companyDeleteUserIdAction(index))
      .then((res) => {
        getCompanyUserList();
        // toast.success("User deleted");
        if (res.status != 200) {
          ToastUtils.serverError(t(`statusCodes.${res?.code}`));
        } else {
          toast.success(t(`statusCodes.${res?.code}`));
        }
      })
      .catch((e) => {
        // toast.error(e.data?.message);
      });
  };

  const handleEditUser = async (e, userId, role) => {
    e.preventDefault();
    if (role === "CU" || role === "RU") {
      navigate(
        getPathPrefix(
          `company-user/update-user/${userId}?t=${USER_FORM_OPTION.UPDATE_USER}`
        )
      );
    } else if (role === "CA") {
      console.log("userId", companyUserListData, userId);
      if (userId === companyUserId) {
        navigate(
          getPathPrefix(
            `company-user/update-user?t=${USER_FORM_OPTION.UPDATE_ADMIN}`
          )
        );
      } else {
        navigate(
          getPathPrefix(
            `company-user/update-user/${userId}?t=${USER_FORM_OPTION.UPDATE_ADMIN_USER}`
          )
        );
      }
    }

    /* await dispatch(companyUserDetailsAction(userId))
      .then((res) => {
        navigate(
          getPathPrefix(`update-user/${userId}?t=${USER_FORM_OPTION.UPDATE_USER}`)
        );
        toast.success(e.data.message);
      })
      .catch((e) => {
        toast.error(e.data.message);
      }); */
  };

  const getConfigNames = (configIds) => {
    return configList
      .filter((e) => configIds.includes(e.dataStreamConfigId))
      .map((e) => e.customDataStreamName)
      .join(", ");
  };

  const companyAdminList = useMemo(() => {
    const finalData = companyUserListData;
    return finalData.filter((x) => x.userRoleType === "CA");
  }, [companyUserListData]);

  const companyUserList = useMemo(() => {
    const finalData = companyUserListData;
    return finalData.filter((x) => x.userRoleType === "CU");
  }, [companyUserListData]);

  return (
    <div className="container-scroller">
      <div className="container-fluid page-body-wrapper p-0">
        <div className="main-panel">
          <div className="content-wrapper">
            <AiccBreadCrumb
              title={t("userList.mainBreadcrumbTitle")}
              items={[
                {
                  name:
                    searchParams.get("t") === "ru"
                      ? t("userList.routingBreadcrumbName")
                      : t("userList.routingUserBreadcrumbName"),
                },
              ]}
            />
            <div className="col-12">
              <div className="card main-card-table">
                <div className="card-header">
                  <div className="row align-items-center">
                    <div className="col-12 col-md-4">
                      <div className="card-label">
                        <img
                          src="/assets/images/user.svg"
                          alt=""
                          className="me-2"
                        />{" "}
                        {/* {t("userList.title")} */}
                        {searchParams.get("t") === "ru"
                          ? t("userList.routingTitle")
                          : t("userList.title")}
                      </div>
                    </div>
                    <div className="col-12 col-md-8">
                      <div className="d-block d-md-flex justify-content-end">
                        <NavLink
                          to={getPathPrefix(
                            `company-user/create-user?t=${USER_FORM_OPTION.CREATE_USER}`
                          )}
                          type="button"
                          className="btn btn-main-primary"
                        >
                          <i className="fa-solid fa-plus me-2"></i>
                          {t("userList.addUserButtonTitle")}
                        </NavLink>
                        <a
                          type="button"
                          className="btn ms-md-3 btn-main-primary"
                          onClick={() => {
                            getCompanyUserList();
                          }}
                        >
                          <i className="fa-sharp fa-solid fa-rotate me-2"></i>
                          {t("userList.refreshUserButtonTitle")}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                {/* for search user list */}
                <div className="card-body">
                  <div className="form">
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={t("userList.searchHint")}
                            value={search}
                            onChange={(e) => {
                              setSearch(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row px-3 mb-3">
                  <h6 className="main-content-sub-label">
                    {t("userList.adminUsers")}
                  </h6>
                  {companyAdminList?.length > 0 &&
                    companyAdminList?.map((item, index) => {
                      const renderCheckBox = () => (
                        <>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            /* disabled={
                            userFullData.superAdminId === user.superAdminId
                          } */
                            onChange={() => {
                              if (item.companyUserId == companyUserId) {
                                return;
                              }
                              checkHandler(item.companyUserId, !item.isActive);
                            }}
                            value=""
                            id={"flexCheckDefault" + item.companyUserId}
                            checked={item.isActive}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={"flexCheckDefault" + item.companyUserId}
                          >
                            {t("CommonKeys.isActiveLabel")}
                          </label>
                        </>
                      );
                      return (
                        <div
                          className="col-12 col-lg-6 col-xl-4"
                          // key={item.superAdminId}
                        >
                          <div className="h-100 user-card">
                            <div
                              className="card-body d-flex justify-content-between"
                              style={{ paddingRight: "0px" }}
                            >
                              <div
                                className="align-items-start d-flex flex-fill gap-4"
                                // style={{ minWidth: "165px" }}
                              >
                                <img
                                  width="80"
                                  height="80"
                                  alt=""
                                  className="rounded-circle p-1 border bg-white"
                                  src={
                                    item.profilePicture
                                      ? item.profilePicture
                                      : profilePic
                                  }
                                />
                                <div className="d-flex flex-column flex-fill user-card-info">
                                  <h5 className="mb-1">
                                    {nameCapitalize(item.firstName)}{" "}
                                    {nameCapitalize(item.lastName)}
                                  </h5>

                                  <div className="d-flex align-items-baseline">
                                    <div className=" d-flex flex-fill">
                                      <div
                                        className="form-check"
                                        style={{
                                          paddingLeft: "0.3rem",
                                          marginLeft: "12px",
                                        }}
                                      >
                                        {item.companyUserId == companyUserId ? (
                                          <a
                                            data-bs-toggle="modal"
                                            data-bs-target={`#noStatusChange${item.companyUserId}`}
                                            className="cursor-pointer"
                                            style={{
                                              color: "black",
                                            }}
                                          >
                                            {renderCheckBox()}
                                          </a>
                                        ) : (
                                          renderCheckBox()
                                        )}
                                      </div>
                                    </div>
                                    <div
                                      className="user-card-icons mt-1"
                                      style={{ minWidth: "90px" }}
                                    >
                                      <span
                                        onClick={(e) =>
                                          handleEditUser(
                                            e,
                                            item.companyUserId,
                                            item.userRoleType
                                          )
                                        }
                                        className="cursor-pointer"
                                      >
                                        <a>
                                          <i className="fa-regular fa-pen-to-square"></i>
                                        </a>
                                      </span>

                                      <span
                                        onClick={() => {
                                          navigate(
                                            getPathPrefix(
                                              `company-user/update-user/${item.companyUserId}?t=${USER_FORM_OPTION.VIEW_USER}`
                                            )
                                          );
                                        }}
                                        className="cursor-pointer"
                                      >
                                        <a>
                                          <i className="fa-regular fa-eye"></i>
                                        </a>
                                      </span>

                                      <span>
                                        <a
                                          href="#"
                                          data-bs-toggle="modal"
                                          data-bs-target={`#DeleteUser-${item.companyUserId}`}
                                        >
                                          <i className="fa-regular fa-trash-can"></i>
                                        </a>
                                      </span>

                                      {item.companyUserId == companyUserId ? (
                                        <>
                                          <ConfirmationModal
                                            title={t(
                                              "userList.canNotDeleteOwn"
                                            )}
                                            modalId={`DeleteUser-${item.companyUserId}`}
                                            singleButton
                                          />
                                          <ConfirmationModal
                                            title={t("userList.canNotInactive")}
                                            modalId={`noStatusChange${item.companyUserId}`}
                                            singleButton={true}
                                          />
                                        </>
                                      ) : (
                                        <ConfirmationModal
                                          title={t(
                                            "userList.deleteCompanyUser",
                                            {
                                              userName:
                                                item.firstName +
                                                " " +
                                                item.lastName,
                                            }
                                          )}
                                          modalId={`DeleteUser-${item.companyUserId}`}
                                          onYesClicked={() => {
                                            handleDeleteUser(
                                              item.companyUserId
                                            );
                                          }}
                                        />
                                      )}
                                    </div>
                                  </div>

                                  <marquee>
                                    {getConfigNames(
                                      item.dataStreamConfigIds ?? []
                                    )}
                                  </marquee>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  {companyAdminList?.length === 0 && (
                    <div
                      class="align-items-center d-flex justify-content-center text-center text-no-data"
                      style={{ height: "20vh" }}
                    >
                      {t("userList.noCompanyAdminFound")}
                    </div>
                  )}
                  <br />
                  <hr />
                  <h6 className="main-content-sub-label">
                    {" "}
                    {t("userList.companyUsers")}
                  </h6>
                  {companyUserList?.length > 0 &&
                    companyUserList?.map((item, index) => {
                      const renderCheckBox = () => (
                        <>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            /* disabled={
                            userFullData.superAdminId === user.superAdminId
                          } */
                            onChange={() => {
                              if (item.companyUserId == companyUserId) {
                                return;
                              }
                              checkHandler(item.companyUserId, !item.isActive);
                            }}
                            value=""
                            id={"flexCheckDefault" + item.companyUserId}
                            checked={item.isActive}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={"flexCheckDefault" + item.companyUserId}
                          >
                            {t("CommonKeys.isActiveLabel")}
                          </label>
                        </>
                      );
                      return (
                        <div
                          className="col-12 col-lg-6 col-xl-4 mb-3"
                          // key={item.superAdminId}
                        >
                          <div className="h-100 user-card">
                            <div
                              className="card-body d-flex justify-content-between"
                              style={{ paddingRight: "0px" }}
                            >
                              <div
                                className="align-items-start d-flex flex-fill gap-4"
                                // style={{ minWidth: "165px" }}
                              >
                                <img
                                  width="80"
                                  height="80"
                                  alt=""
                                  className="rounded-circle p-1 border bg-white"
                                  src={
                                    item.profilePicture
                                      ? item.profilePicture
                                      : profilePic
                                  }
                                />
                                <div className="d-flex flex-column flex-fill user-card-info">
                                  <h5 className="mb-1">
                                    {nameCapitalize(item.firstName)}{" "}
                                    {nameCapitalize(item.lastName)}
                                  </h5>

                                  <div className="d-flex align-items-baseline">
                                    <div className=" d-flex flex-fill">
                                      <div
                                        className="form-check"
                                        style={{
                                          marginLeft: "12px",
                                          paddingLeft: "0.3rem",
                                        }}
                                      >
                                        {item.companyUserId == companyUserId ? (
                                          <a
                                            data-bs-toggle="modal"
                                            data-bs-target={`#noStatusChange${item.companyUserId}`}
                                            className="cursor-pointer"
                                            style={{
                                              color: "black",
                                            }}
                                          >
                                            {renderCheckBox()}
                                          </a>
                                        ) : (
                                          renderCheckBox()
                                        )}
                                      </div>
                                    </div>
                                    <div
                                      className="user-card-icons mt-1"
                                      style={{ minWidth: "90px" }}
                                    >
                                      <span
                                        onClick={(e) =>
                                          handleEditUser(
                                            e,
                                            item.companyUserId,
                                            item.userRoleType
                                          )
                                        }
                                        className="cursor-pointer"
                                      >
                                        <a>
                                          <i className="fa-regular fa-pen-to-square"></i>
                                        </a>
                                      </span>

                                      <span
                                        onClick={() => {
                                          navigate(
                                            getPathPrefix(
                                              `company-user/update-user/${item.companyUserId}?t=${USER_FORM_OPTION.VIEW_USER}`
                                            )
                                          );
                                        }}
                                        className="cursor-pointer"
                                      >
                                        <a>
                                          <i className="fa-regular fa-eye"></i>
                                        </a>
                                      </span>

                                      <span>
                                        <a
                                          href="#"
                                          data-bs-toggle="modal"
                                          data-bs-target={`#DeleteUser-${item.companyUserId}`}
                                        >
                                          <i className="fa-regular fa-trash-can"></i>
                                        </a>
                                      </span>

                                      {item.companyUserId == companyUserId ? (
                                        <>
                                          <ConfirmationModal
                                            title={t(
                                              "userList.canNotDeleteOwn"
                                            )}
                                            modalId={`DeleteUser-${item.companyUserId}`}
                                            singleButton
                                          />
                                          <ConfirmationModal
                                            title={t("userList.canNotInactive")}
                                            modalId={`noStatusChange${item.companyUserId}`}
                                            singleButton={true}
                                          />
                                        </>
                                      ) : (
                                        <ConfirmationModal
                                          title={t(
                                            "userList.deleteCompanyUser",
                                            {
                                              userName:
                                                item.firstName +
                                                " " +
                                                item.lastName,
                                            }
                                          )}
                                          modalId={`DeleteUser-${item.companyUserId}`}
                                          onYesClicked={() => {
                                            handleDeleteUser(
                                              item.companyUserId
                                            );
                                          }}
                                        />
                                      )}
                                    </div>
                                  </div>

                                  <marquee>
                                    {getConfigNames(
                                      item.dataStreamConfigIds ?? []
                                    )}
                                  </marquee>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  {companyUserList?.length === 0 && (
                    <div
                      class="align-items-center d-flex justify-content-center text-center text-no-data"
                      style={{ height: "20vh" }}
                    >
                      {t("userList.noCompanyUserFound")}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="d-sm-flex justify-content-center">
              {isLoading && (
                <img
                  src={"/assets/images/loader_gif.gif"}
                  color={colors.white}
                  height={"50"}
                  width={"50"}
                  align={"center"}
                />
              )}
            </div>
          </div>
          <footer className="footer">
            <div className="d-sm-flex justify-content-center">
              <p className="mb-0">
                Copyright © 2023{" "}
                <a
                  rel="noreferrer"
                  style={{ textDecorationLine: "underline !important" }}
                  href="https://scandiautomation.com/"
                  target="_blank"
                >
                  Scandi Automation
                </a>
                .
              </p>
            </div>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default UserListPage;
