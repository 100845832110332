import React, { useEffect, useState } from "react";
import ReactJson from "react-json-view";
import { Accordion } from "./Accordian";
// import { processSchema, schemaData } from "./playground";

var isobject = function (x) {
  return Object.prototype.toString.call(x) === "[object Object]";
};
function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

const ListMaker = (item, handleClick) => {
  return (
    <>
      {item?.fields?.length === 0 ? (
        <a
          href="#"
          data-bs-toggle="modal"
          data-bs-target="#SimpleJsonPathModal"
          onClick={() => handleClick(item)}
        >
          <p className="schema-name">{item.path}</p>
        </a>
      ) : (
        <Accordion
          title={item?.path?.length > 0 ? item?.path : item?.name}
          onClick={() => handleClick(item)}
        >
          {item?.fields?.map((childItem) => {
            return ListMaker(childItem, handleClick, item?.name);
          })}
        </Accordion>
      )}
    </>
  );
};

const listItems = (finalList, handleClick) =>
  finalList?.map((item) => {
    let menuItem = ListMaker(item, handleClick);
    return menuItem;
  });

var getkeys = function (obj, prefix) {
  var keys = Object.keys(obj);
  prefix = prefix ? prefix + "." : "";
  return keys.reduce(function (result, key) {
    if (isobject(obj[key])) {
      // if(Number(+key+1)){
      //     // console.log(prefix + `[${key}]`);
      //     // prefix = prefix + `[${key}]`;
      //     prefix = prefix.replace(`.${key}.`,`[${key}]`);
      //     result = result.concat(getkeys(obj[key], prefix));
      // }else{
      if (isNumeric(key)) {
        result = result.concat(
          getkeys(obj[key], `${prefix.slice(0, prefix.length - 1)}[${key}]`)
        );
      } else {
        result = result.concat(getkeys(obj[key], prefix + key));
      }

      // }
    } else if (
      !isobject(obj[key]) &&
      typeof obj[key] != "string" &&
      obj[key] &&
      obj[key].length > 0
    ) {
      result = result.concat(getkeys(obj[key], `${prefix}${key}5`));
    } else {
      result.push(prefix + key);
    }
    // console.log('[[[[[[[[[[[[[[[[[',result)
    return result;
  }, []);
};
// getkeys(data,'');
// const latest = getkeys(data, "");
// console.log(latest);
// const newr =latest.map(doc=>{
//     let str = doc.split('.')
//     let dd = str.find(ele=>typeof ele == 'number')
//     if(dd){
//         ddt=`[${dd}]`
//         const index = str.indexOf(dd)
//         if (index !== -1) {
//             str[index] = ddt;
//         }
//         str.join('.')

//     }

//     return str
// })

function UploadSchema() {
  const [latest, setlatest] = useState(null);
  const [newData, setnewData] = useState([]);

  const [modalData, setModalData] = useState({
    parentHeader: "",
    fieldName: "",
    pathName: "",
    groupName: "",
    fieldType: "",
    indexNumber: "",
    aggregateFunction: "",
    fields: [],
    fieldList: [],
  });

  const handleLevelClick = (selectedOption) => {
    console.log(JSON.parse(selectedOption.target.value));
    modalData.fieldList.push(JSON.parse(selectedOption.target.value));
    setModalData({
      ...modalData,
    });
  };

  const handleClickModal = (item) => {
    setModalData({
      ...modalData,
      pathName: item.path,
      parentHeader: item.name,
      fields: item.fields,
    });
  };

  const uploadFile = (e) => {
    const fileReader = new FileReader();
    var newData = [];
    fileReader.readAsText(e.target.files[0], "UTF-8");
    fileReader.onload = (e) => {
      // console.log("e.target.result", e.target.result);
      const uploadData = JSON.parse(e.target.result);
      let heading = "";
      let finalOutput = {};
      processSchema(null, uploadData);

      function processSchema(header, data) {
        if (Array.isArray(data)) {
          heading = header;
          printObject(data[0], data.length);
        } else if (typeof data == "object") {
          heading = header;
          printObject(data);
        }
      }

      function printObject(data, index) {
        var keys = Object.keys(data);
        if (heading != null) {
          if (index != null) {
            let ss = [];
            keys.forEach((key) => {
              ss.push(heading + `.` + key);
            });
            finalOutput[heading] = ss;
          } else finalOutput[heading] = keys;
        }
        keys.forEach((key) => {
          processSchema(key, data[key]);
        });
      }

      let schemaLevel = 0;
      let result = [
        { name: Object.keys(finalOutput)[0], fields: [], path: "" },
      ];
      processKeys(Object.keys(finalOutput)[0]);
      populateParent(result);
      function populateParent(source) {
        if (Array.isArray(source)) source = source[0];
        source.fields.map((s) => {
          if (source.path !== "") {
            s.path = source.path + "." + source.name + "." + s.name;
          } else {
            s.path = source.name + "." + s.name;
          }
          s.path = Array.from(new Set(s.path.split("."))).join(".");
          populateParent(s);
        });
      }

      function processKeys(key) {
        finalOutput[key].map((field) => {
          schemaLevel = schemaLevel + 1;
          findAndAddFiled(result, key, field);
          if (finalOutput[field]) {
            processKeys(field);
          } else {
            schemaLevel = schemaLevel - 1;
          }
        });
        schemaLevel = schemaLevel - 1;
      }

      function findAndAddFiled(source, parent, child) {
        source.forEach((res) => {
          if (res.name === parent) {
            if (res.fields.filter((field) => field.name === child).length === 0)
              res.fields.push({ name: child, fields: [] });
          } else {
            findAndAddFiled(res.fields, parent, child);
          }
        });
      }
      // let data = e.target.result;
      // console.log("data...................", data);
      // console.log("latest..................>>>>>>>>>>>>.", latest);
      // setlatest(JSON.parse(data));
      // newData.push(data);
      setlatest(result);
      // const newData = JSON.parse(e.target.result);
      // console.log(
      //   newData.Estimate.CalculationResult.SummaryResults.SummaryResult[0],
      //   "lkjhg"
      // );
      // const data1 =
      //   newData.Estimate.CalculationResult.SummaryResults.SummaryResult[0];
      // console.log("data1", Object.keys(data[0]));
      // const newData1 = Object.keys(data1);
      // setnewData(newData1);

      // setFiles(e.target.result);
    };
  };
  console.log("latest=========================", latest);

  return (
    <div>
      <div className="container-scroller">
        {/* <nav className="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
          <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
            <a className="navbar-brand brand-logo mr-5" href="index.html">
              <img
                src="/assets/images/AI_Cost_Control_logo.png"
                className="mr-2"
                alt="logo"
              />
            </a>
            <a className="navbar-brand brand-logo-mini" href="index.html">
              <img src="/assets/images/AI.png" alt="logo" />
            </a>
          </div>
          <div className="navbar-menu-wrapper d-flex align-items-center justify-content-end">
            <button
              className="navbar-toggler navbar-toggler align-self-center"
              type="button"
              data-toggle="minimize"
            >
              <span>
                <i className="fa-solid fa-bars-staggered"></i>
              </span>
            </button>
            <ul className="navbar-nav navbar-nav-right">
              <li className="nav-item nav-profile dropdown">
                <a
                  className="nav-link dropdown-toggle p-0"
                  href="#"
                  data-toggle="dropdown"
                  id="profileDropdown"
                >
                  <div className="d-flex user-box align-items-center">
                    <div className="user-info">
                      <p className="user-name mb-0">John Doe</p>
                      <p className="designattion mb-0">Admin</p>
                    </div>
                    <img
                      src="/assets/images/avatars/avatar-1.png"
                      className="user-img"
                      alt="user avatar"
                    />
                  </div>
                </a>
                <div
                  className="dropdown-menu dropdown-menu-right navbar-dropdown"
                  aria-labelledby="profileDropdown"
                >
                  <a className="dropdown-item">
                    <i className="fa-solid fa-user"></i>Update Profile
                  </a>
                  <a className="dropdown-item" href="reset-password.html">
                    <i className="fa-solid fa-lock"></i>Change password
                  </a>
                  <a className="dropdown-item" href="login.html">
                    <i className="fa-solid fa-right-from-bracket"></i>Logout
                  </a>
                </div>
              </li>
            </ul>
            <button
              className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
              type="button"
              data-toggle="offcanvas"
            >
              <span>
                <i className="fa-solid fa-bars-staggered"></i>
              </span>
            </button>
          </div>
        </nav> */}
        {/* <!-- partial --> */}
        <div className="container-fluid page-body-wrapper p-0">
          {/* <nav
            className="sidebar sidebar-offcanvas"
            id="sidebar"
            data-simplebar="true"
          >
            <ul className="nav">
              <li className="nav-item">
                <a className="nav-link" href="index.html">
                  <i className="fa-solid fa-house menu-icon"></i>
                  <span className="menu-title">Dashboard</span>
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-toggle="collapse"
                  href="#ui-basic"
                  aria-expanded="false"
                  aria-controls="ui-basic"
                >
                  <i className="fa-solid fa-calendar-day menu-icon"></i>
                  <span className="menu-title">Estimate</span>
                  <i className="menu-arrow"></i>
                </a>
                <div className="collapse" id="ui-basic">
                  <ul className="nav flex-column sub-menu">
                    <li className="nav-item">
                      {" "}
                      <a className="nav-link" href="rule-engine.html">
                        <img src="/assets/images/engine.svg" alt="" />
                        Rule Engine
                      </a>
                    </li>
                    <li className="nav-item">
                      {" "}
                      <a className="nav-link" href="routing.html">
                        <img src="/assets/images/routing.svg" alt="" />
                        Routing
                      </a>
                    </li>
                    <li className="nav-item">
                      {" "}
                      <a className="nav-link" href="geographical-control.html">
                        <img src="/assets/images/globe.svg" alt="" />
                        Geographical Control
                      </a>
                    </li>
                    <li className="nav-item">
                      {" "}
                      <a className="nav-link" href="documents.html">
                        <img src="/assets/images/document.svg" alt="" />
                        Document
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  <i className="fa-solid fa-clipboard-check menu-icon"></i>
                  <span className="menu-title">Validation</span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  <i className="fa-solid fa-file-lines menu-icon"></i>
                  <span className="menu-title">Inspect Report</span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  <i className="fa-solid fa-file-invoice menu-icon"></i>
                  <span className="menu-title">E-Invoice</span>
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-toggle="collapse"
                  href="#user"
                  aria-expanded="false"
                >
                  <i className="fa-solid fa-users menu-icon"></i>
                  <span className="menu-title">Company User</span>
                  <i className="menu-arrow"></i>
                </a>
                <div className="collapse" id="user">
                  <ul className="nav flex-column sub-menu">
                    <li className="nav-item">
                      {" "}
                      <a className="nav-link" href="user-list.html">
                        <img src="/assets/images/user.svg" alt="" />
                        User List
                      </a>
                    </li>
                    <li className="nav-item">
                      {" "}
                      <a className="nav-link" href="create-user.html">
                        <i className="fa-solid fa-arrow-right"></i>Create User
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-toggle="collapse"
                  href="#company"
                  aria-expanded="false"
                >
                  <i className="fa-solid fa-building menu-icon"></i>
                  <span className="menu-title">Company</span>
                  <i className="menu-arrow"></i>
                </a>
                <div className="collapse" id="company">
                  <ul className="nav flex-column sub-menu">
                    <li className="nav-item">
                      {" "}
                      <a className="nav-link" href="view-company.html">
                        <i className="fa-solid fa-building"></i>View Company
                      </a>
                    </li>
                    <li className="nav-item">
                      {" "}
                      <a className="nav-link" href="manage-subscription.html">
                        <i className="fa-solid fa-money-bill"></i>Manage
                        Subscription
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-toggle="collapse"
                  href="#data-stream"
                  aria-expanded="false"
                >
                  <i className="fa-solid fa-network-wired menu-icon"></i>
                  <span className="menu-title">Data Stream</span>
                  <i className="menu-arrow"></i>
                </a>
                <div className="collapse" id="data-stream">
                  <ul className="nav flex-column sub-menu">
                    <li className="nav-item">
                      {" "}
                      <a className="nav-link" href="#">
                        <i className="fa-solid fa-arrow-right"></i>Data Stream
                        Group
                      </a>
                    </li>
                    <li className="nav-item">
                      {" "}
                      <a className="nav-link" href="#">
                        <i className="fa-solid fa-arrow-right"></i>Data Stream
                        Schema
                      </a>
                    </li>
                    <li className="nav-item">
                      {" "}
                      <a className="nav-link" href="field-configuration.html">
                        <i className="fa-solid fa-arrow-right"></i>Field
                        Configuration
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-toggle="collapse"
                  href="#help-support"
                  aria-expanded="false"
                >
                  <i className="fa-solid fa-circle-info menu-icon"></i>
                  <span className="menu-title">Help & Support</span>
                  <i className="menu-arrow"></i>
                </a>
                <div className="collapse" id="help-support">
                  <ul className="nav flex-column sub-menu">
                    <li className="nav-item">
                      {" "}
                      <a className="nav-link" href="#">
                        <i className="fa-solid fa-lightbulb"></i>Knowledge Base
                      </a>
                    </li>
                    <li className="nav-item">
                      {" "}
                      <a className="nav-link" href="contact-aicc.html">
                        <i className="fa-solid fa-square-phone"></i>Contact AICC
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
            <div className="version-card">
              <h6>Release Version</h6>
              <h6 className="mb-0">
                <strong>AI 1.0.1</strong>
              </h6>
            </div>
          </nav> */}
          {/* <!-- partial --> */}
          <div className="main-panel">
            <div className="content-wrapper">
              {/* <!-- breadcrumb-start --> */}
              <div className="page-breadcrumb d-none d-md-flex align-items-center mb-5">
                <div className="breadcrumb-title pe-3">Data Stream</div>
                <div className="ps-3">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb mb-0 p-0">
                      <li className="breadcrumb-item">
                        <a href="#">
                          <i className="fa-solid fa-house"></i>
                        </a>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Field Configuration
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Upload Schema
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
              {/* <!-- breadcrumb-end --> */}
              <div className="col-12 col-lg-10 col-md-12 mx-auto">
                <div className="card main-card-table">
                  <div className="card-header">
                    <div className="row align-items-center">
                      <div className="col-12">
                        <div className="card-label mb-0">Upload Schema</div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="form">
                      <div className="row">
                        <div className="col-lg-8 col-md-8">
                          <div className="form-group">
                            <label for="" className="form-label">
                              Select Path
                            </label>

                            {/* <select className="form-select">
                              <option selected>Select</option>
                              <option value="1">One</option>
                              <option value="2">Two</option>
                              <option value="3">Three</option>
                            </select> */}
                          </div>
                        </div>
                        <div className="col-12">
                          <label
                            htmlFor="upload"
                            type="button"
                            className="btn btn-red"
                          >
                            <i className="fa-solid fa-upload me-2"></i>
                            Upload
                          </label>
                          <input
                            id="upload"
                            // value={file}
                            onChange={(e) => uploadFile(e)}
                            type="file"
                            style={{ display: "none" }}
                          />
                        </div>
                      </div>
                      <div className="row mt-4">
                        <div className="col-lg-6 col-md-12 col-12">
                          <div className="card field-configurator-box radius-15">
                            <div className="card-header">Json Stream Paths</div>
                            <div className="card-body overflow-auto">
                              <div className="field-configurator-path">
                                {latest && listItems(latest, handleClickModal)}
                                {/* {latest?.map((item, index) => {
                                  // console.log(item, "ppppp");
                                  return (
                                    <ul key={Math.random() * 28736729}>
                                      <li>
                                        <a
                                          href="#"
                                          data-bs-toggle="modal"
                                          data-bs-target="#SimpleJsonPathModal"
                                          onClick={() => {
                                            setModalData({
                                              ...modalData,
                                              ["pathName"]: item,
                                            });
                                          }}
                                        >
                                          {item}
                                        </a>
                                      </li>
                                    </ul>
                                  );
                                })} */}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12">
                          <div className="card field-configurator-box radius-15">
                            <div className="card-header">User Grouping</div>
                            <div className="card-body"></div>
                          </div>
                        </div>
                      </div>
                      {/* <!-- Simple Json Path Modal --> */}
                      <div
                        className="modal fade"
                        id="SimpleJsonPathModal"
                        tabindex="-1"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog modal-dialog-centered modal-lg">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5
                                className="modal-title"
                                id="exampleModalLabel"
                              >
                                Configurator Simple Json Path
                              </h5>
                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              >
                                <i className="fa-solid fa-xmark"></i>
                              </button>
                            </div>
                            <div className="modal-body">
                              <div className="form">
                                <div className="row">
                                  <div className="col-12 col-lg-6 col-md-6">
                                    <div className="form-group">
                                      <label for="" className="form-label">
                                        Parent Header
                                      </label>
                                      <input
                                        type="text"
                                        name="parentHeader"
                                        value={modalData.parentHeader}
                                        onChange={(e) => {
                                          setModalData({
                                            ...modalData,
                                            ["parentHeader"]: e.target.value,
                                          });
                                          console.log(
                                            modalData,
                                            "ooooooooooooooooo"
                                          );
                                        }}
                                        className="form-control"
                                        placeholder={modalData.pathName}
                                        readonly
                                      />
                                    </div>
                                  </div>
                                  <div className="col-12 col-lg-6 col-md-6">
                                    <div className="form-group">
                                      <label for="" className="form-label">
                                        Field Name
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="fieldName"
                                        value={modalData.fieldName}
                                        onChange={(e) => {
                                          setModalData({
                                            ...modalData,
                                            ["fieldName"]: e.target.value,
                                          });
                                        }}
                                        placeholder="Field Name"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-12 col-lg-6 col-md-6">
                                    <div className="form-group">
                                      <label for="" className="form-label">
                                        Path Name
                                      </label>
                                      <input
                                        type="text"
                                        name="pathName"
                                        value={modalData.pathName}
                                        onChange={(e) => {
                                          setModalData({
                                            ...modalData,
                                            ["pathName"]: e.target.value,
                                          });
                                        }}
                                        className="form-control"
                                        placeholder="Person.Name"
                                        readonly
                                      />
                                    </div>
                                  </div>
                                  <div className="col-12 col-lg-6 col-md-6">
                                    <div className="form-group">
                                      <label for="" className="form-label">
                                        Group Name
                                      </label>
                                      <input
                                        type="text"
                                        name="groupName"
                                        value={modalData.groupName}
                                        onChange={(e) => {
                                          setModalData({
                                            ...modalData,
                                            ["groupName"]: e.target.value,
                                          });
                                        }}
                                        className="form-control"
                                        placeholder="Group Name"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-12 col-lg-6 col-md-6">
                                    <div className="form-group">
                                      <label for="" className="form-label">
                                        Child Components
                                      </label>
                                      <select
                                        className="form-select"
                                        onChange={handleLevelClick}
                                      >
                                        <option selected="">Select</option>
                                        {modalData.fields?.map((tt, index) => {
                                          return (
                                            <>
                                              <option
                                                value={JSON.stringify(tt)}
                                              >
                                                {tt.name}
                                              </option>
                                            </>
                                          );
                                        })}
                                      </select>

                                      {modalData.fieldList?.map(
                                        (field, index) => {
                                          console.log(field);
                                          return (
                                            <>
                                              <div>
                                                <br />
                                                <br />
                                                <select
                                                  className="form-select"
                                                  onChange={handleLevelClick}
                                                >
                                                  <option selected="">
                                                    Select
                                                  </option>
                                                  {field.fields.map(
                                                    (kk, index) => {
                                                      return (
                                                        <>
                                                          <option
                                                            value={JSON.stringify(
                                                              kk
                                                            )}
                                                          >
                                                            {kk.name}
                                                          </option>
                                                        </>
                                                      );
                                                    }
                                                  )}
                                                </select>
                                              </div>
                                            </>
                                          );
                                        }
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-12 col-lg-6 col-md-6">
                                    <div className="form-group">
                                      <label for="" className="form-label">
                                        Field Type
                                      </label>
                                      <select className="form-select">
                                        <option selected="">Select</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className="col-12 col-lg-6 col-md-6">
                                    <div className="form-group">
                                      <label for="" className="form-label">
                                        Index Number
                                      </label>
                                      <input
                                        disabled={
                                          modalData.pathName ==
                                            "Estimate.CalculationId" ||
                                          modalData.pathName ==
                                            "Estimate.ContentType"
                                        }
                                        type="text"
                                        name="indexNumber"
                                        value={modalData.indexNumber}
                                        onChange={(e) => {
                                          setModalData({
                                            ...modalData,
                                            ["indexNumber"]: e.target.value,
                                          });
                                        }}
                                        className="form-control"
                                        placeholder="Index Number"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-12 col-lg-6 col-md-6">
                                    <div className="form-group">
                                      <label for="" className="form-label">
                                        Aggregate Function
                                      </label>
                                      <select
                                        className="form-select"
                                        disabled={
                                          modalData.pathName ==
                                            "Estimate.CalculationId" ||
                                          modalData.pathName ==
                                            "Estimate.ContentType"
                                        }
                                      >
                                        <option selected="">Select</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className="text-end mt-3">
                                    <a href="#" type="button" className="btn">
                                      <i className="fa-regular fa-floppy-disk me-2"></i>
                                      Save
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="bottom-border"></div>
                          </div>
                        </div>
                      </div>
                      {/* <!-- Index Json Modal --> */}
                      <div
                        className="modal fade"
                        id="IndexJsonModal"
                        tabindex="-1"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog modal-dialog-centered modal-lg">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5
                                className="modal-title"
                                id="exampleModalLabel"
                              >
                                Configurator Index Json Path
                              </h5>
                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              >
                                <i className="fa-solid fa-xmark"></i>
                              </button>
                            </div>
                            <div className="modal-body">
                              <div className="form">
                                <div className="row">
                                  <div className="col-12 col-lg-6 col-md-6">
                                    <div className="form-group">
                                      <label for="" className="form-label">
                                        Field Name
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Field Name"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-12 col-lg-6 col-md-6">
                                    <div className="form-group">
                                      <label for="" className="form-label">
                                        Parent Header
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Hobies"
                                        readonly
                                      />
                                    </div>
                                  </div>
                                  <div className="col-12 col-lg-6 col-md-6">
                                    <div className="form-group">
                                      <label for="" className="form-label">
                                        Path Name
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Hobby.MonthlyCost"
                                        readonly
                                      />
                                    </div>
                                  </div>
                                  <div className="col-12 col-lg-6 col-md-6">
                                    <div className="form-group">
                                      <label for="" className="form-label">
                                        Group Name
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Group Name"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-12 col-lg-6 col-md-6">
                                    <div className="form-group">
                                      <label for="" className="form-label">
                                        Field Type
                                      </label>
                                      <select className="form-select">
                                        <option selected="">Select</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className="col-12 col-lg-6 col-md-6">
                                    <div className="form-group">
                                      <label for="" className="form-label">
                                        Index Number
                                      </label>

                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Index Number"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-12 col-lg-6 col-md-6">
                                    <div className="form-group">
                                      <label for="" className="form-label">
                                        Aggregate Function
                                      </label>
                                      <select className="form-select">
                                        <option selected="">Select</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className="text-end mt-3">
                                    <a href="#" type="button" className="btn">
                                      <i className="fa-regular fa-floppy-disk me-2"></i>
                                      Save
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="bottom-border"></div>
                          </div>
                        </div>
                      </div>
                      {/* <!-- Json Array Modal --> */}
                      <div
                        className="modal fade"
                        id="JsonArrayModal"
                        tabindex="-1"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog modal-dialog-centered modal-lg">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5
                                className="modal-title"
                                id="exampleModalLabel"
                              >
                                Configurator Json Array
                              </h5>
                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              >
                                <i className="fa-solid fa-xmark"></i>
                              </button>
                            </div>
                            <div className="modal-body">
                              <div className="form">
                                <div className="row">
                                  <div className="col-12 col-lg-6 col-md-6">
                                    <div className="form-group">
                                      <label for="" className="form-label">
                                        Field Name
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Field Name"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-12 col-lg-6 col-md-6">
                                    <div className="form-group">
                                      <label for="" className="form-label">
                                        Parent Header
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Hobies"
                                        readonly
                                      />
                                    </div>
                                  </div>
                                  <div className="col-12 col-lg-6 col-md-6">
                                    <div className="form-group">
                                      <label for="" className="form-label">
                                        Path Name
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="MonthlyCost"
                                        readonly
                                      />
                                    </div>
                                  </div>
                                  <div className="col-12 col-lg-6 col-md-6">
                                    <div className="form-group">
                                      <label for="" className="form-label">
                                        Group Name
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Group Name"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-12 col-lg-6 col-md-6">
                                    <div className="form-group">
                                      <label for="" className="form-label">
                                        Field Type
                                      </label>
                                      <select className="form-select">
                                        <option selected="">Select</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className="col-12 col-lg-6 col-md-6">
                                    <div className="form-group">
                                      <label for="" className="form-label">
                                        Index Number
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Index Number"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-12 col-lg-6 col-md-6">
                                    <div className="form-group">
                                      <label for="" className="form-label">
                                        Aggregate Function
                                      </label>
                                      <select className="form-select">
                                        <option selected="">Select</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className="text-end mt-3">
                                    <a href="#" type="button" className="btn">
                                      <i className="fa-regular fa-floppy-disk me-2"></i>
                                      Save
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="bottom-border"></div>
                          </div>
                        </div>
                      </div>
                      <div className="text-end mt-4">
                        <a href="#" type="button" className="btn">
                          <i className="fa-regular fa-floppy-disk me-2"></i>Save
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- content-wrapper ends --> */}
            {/* <!-- partial:partials/_footer.html --> */}
            <footer className="footer">
              <div className="d-sm-flex justify-content-center">
                <p className="mb-0">
                  Copyright © 2023{" "}
                  <a
                    rel="noreferrer"
                    style={{ textDecorationLine: "underline !important" }}
                    href="https://scandiautomation.com/"
                    target="_blank"
                  >
                    Scandi Automation
                  </a>
                  .
                </p>
              </div>
            </footer>
            {/* <!-- partial --> */}
          </div>
          {/* <!-- main-panel ends --> */}
        </div>
        {/* <!-- page-body-wrapper ends --> */}
      </div>
      {/* <!-- container-scroller --> */}
    </div>
  );
}

export default UploadSchema;
